import { WarningIcon } from '../libs/icons';
import { IonButton } from '@ionic/react';
import React from 'react';

export function MissingSomethingWarning ({
  warningIcon = true,
  title,
  message,
  buttonLink,
  buttonTitle
}: { warningIcon?: boolean, title?: string, message?: string, buttonLink?: string, buttonTitle?: string }) {
  return (<div className={'p-2 text-center'}>
    { warningIcon && <p><WarningIcon className={'text-muted'} size={'3em'}/></p> }
    { title && <h4 className={'text-muted text-center'}>{title}</h4> }
    { message && <p>{message}</p> }
    { buttonLink && buttonTitle && <IonButton routerLink={buttonLink}>{buttonTitle}</IonButton> }
  </div>);
}
