import React, { useContext, useEffect } from 'react';
import * as yup from 'yup';
import { Form, Formik, FormikHelpers } from 'formik';
import { reportException } from '../libs/errors';
import { ImageFileDropInput, IonFormikShortTextarea, UnexpectedFormErrors } from './Forms';
import { IconCancelButton, IconDeleteButton, IconSendButton } from './FormButtons';
import { MessageContent } from './Messages';
import './ChatMessageList.scss';
import { UserLayoutContext } from '../containers/UserLayout';
import { s3UploadMessageImage } from '../libs/storage';
import { useHistory } from 'react-router';
import { IonToolbar } from '@ionic/react';
import { useKeyboardDidShowCount } from '../libs/capacitorApp';

interface ChatMessageInterface {
  id: string;
  text: string;
  imageUrl?: string;
  s3ImageName?: string;
  formattedDate: () => string;
  fromId: () => string;
  fromMe: (connectionOrBusinessId: string) => boolean;
}

export const ChatMessagePageContext = React.createContext<{
  newMessageId: string;
  newMessagePlaceholder: string;
  editMessageId: string;
  setEditMessageId:(editMessageId: string) => void;
    }>({
      newMessageId: 'newMessageId',
      newMessagePlaceholder: 'Enter a new message',
      editMessageId: '',
      setEditMessageId: () => { /* do nothing for not implemented */ }
    });

export function ChatMessageList ({ currentConnectionOrBusinessId, sortedMessages, uneditableMessageIds = new Set<string>(), update, del, create, allowImage }: {
  currentConnectionOrBusinessId: string,
  sortedMessages: ChatMessageInterface[],
  uneditableMessageIds?: Set<string>,
  update: (id: string, text: string) => Promise<void>,
  del: (id: string) => Promise<void>,
  create: (text: string, s3ImageName?: string) => Promise<void>,
  allowImage: boolean
}) {
  const { newMessageId, editMessageId, setEditMessageId } = useContext(ChatMessagePageContext);

  let lastReplyDate = '';
  return (
    <>
      {
        sortedMessages.map((msg, i) => {
          let showDate = false;
          if (lastReplyDate !== msg.formattedDate()) {
            showDate = true;
            lastReplyDate = msg.formattedDate();
          }

          let last = false;
          if (i < sortedMessages.length - 1) {
            const nextReply = sortedMessages[i + 1];
            if (msg.fromId() !== nextReply.fromId() || nextReply.formattedDate() !== lastReplyDate) {
              // this is the last message if the next reply is from a different person or on a different day.
              last = true;
            }
          } else {
            last = true;
          }

          return (
            <div key={msg.id} id={`message${msg.id}`}>
              {showDate && <div className='small text-muted ion-text-center mb-1'>{msg.formattedDate()}</div>}
                <ChatMessage
                  message={msg}
                  mine={msg.fromMe(currentConnectionOrBusinessId)}
                  last={last}
                  editable={msg.fromMe(currentConnectionOrBusinessId) && !uneditableMessageIds.has(msg.id)}
                  editing={editMessageId === msg.id}
                  openEdit={() => setEditMessageId(msg.id)}
                  closeEdit={() => setEditMessageId('')}
                  update={update}
                  del={del}
                  allowImage={allowImage}
                />
            </div>
          );
        })
        //   )
      }
      <div id={newMessageId}>
        <ChatMessageCreateForm create={create} allowImage={allowImage} />
      </div>
    </>
  );
}

function ChatMessage ({ message, mine, last, editable, editing, openEdit, closeEdit, update, del, allowImage }:
  {
    message: ChatMessageInterface,
    mine: boolean,
    last: boolean
    editable: boolean,
    editing: boolean,
    openEdit: () => void,
    closeEdit: () => void,
    update: (id: string, text: string) => Promise<void>,
    del: (id: string) => Promise<void>,
    allowImage: boolean
  }) {
  if (editable && editing) {
    return (<ChatMessageEditForm message={message} close={closeEdit} update={update} del={del} allowImage={allowImage} imageIconSize={'1.5em'}/>);
  }

  return (
    <div className={`bubblerow mb-1 ${mine ? 'mine' : 'yours'} ${!editable ? 'uneditable' : ''} ${last ? 'last mb-3' : ''}`}>
      <div { ...editable && { onClick: openEdit }} className='bubble'>
        <MessageContent message={message}/>
      </div>
    </div>
  );
}

type ChatMessageCRUDType = {
  text: string;
  s3ImageName?: string;
}
const ChatMessageCreateSchema = yup.object({
  text: yup.string().required().default(''),
  s3ImageName: yup.string().notRequired()
});

export function ChatMessageCreateToolbar ({
  placeholder = 'Direct Message...'
}: {
    placeholder?: string,
  }) {
  const history = useHistory<{newMessage?: boolean}>();

  const { newMessageId, editMessageId, setEditMessageId } = useContext(ChatMessagePageContext);

  useEffect(() => {
    if (history.location.state?.newMessage) {
      setEditMessageId(newMessageId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (editMessageId) {
    // hide the footer since we're editing.
    return (<></>);
  }

  return (<IonToolbar id={'chatMessageCreateToolbar'}>
    <div className={'bubblerow create'}>
      <div className={'bubble last py-1'} onClick={() => setEditMessageId(newMessageId)}>
        <input className='w-100' type='text' onClick={() => setEditMessageId(newMessageId)} placeholder={placeholder}/>
      </div>
    </div>
  </IonToolbar>);
}

function ChatMessageCreateForm ({ allowImage, create }:
  {
    allowImage: boolean,
    create: (text: string, s3ImageName?: string) => Promise<void>
  }) {
  const { newMessageId, newMessagePlaceholder, editMessageId, setEditMessageId } = useContext(ChatMessagePageContext);

  const didShowCount = useKeyboardDidShowCount();
  const { contentRef } = useContext(UserLayoutContext);

  useEffect(() => {
    if (editMessageId === newMessageId) {
      // scroll to the top of the id
      const y = document.getElementById(newMessageId)?.offsetTop;
      y && contentRef && contentRef.current && contentRef.current.scrollToPoint(0, y, 500);
    }
  }, [contentRef, didShowCount, editMessageId, newMessageId]);

  if (newMessageId !== editMessageId) {
    return (<></>);
  }

  async function handleSubmit (values: ChatMessageCRUDType & { imageFile?: File }, actions: FormikHelpers<ChatMessageCRUDType>) {
    try {
      if (values.imageFile) {
        try {
          values.s3ImageName = await s3UploadMessageImage(values.imageFile, { shouldResize: 'true' });
        } catch (e) {
          // image upload failed report exception and return from the function early
          reportException(e, 's3UploadMessageImage failed in ChatMessageList ChatMessageCreateForm handleSubmit');
          actions.setStatus(e.message || e);
          actions.setSubmitting(false);
          return;
        }
      }
      await create(values.text, values.s3ImageName);
      actions.setSubmitting(false);
      actions.resetForm();
      setEditMessageId('');
    } catch (e) {
      reportException(e, 'create failed in ChatMessageList ChatMessageCreateForm handleSubmit');
      actions.setStatus(e.message || e);
      actions.setSubmitting(false);
    }
  }

  const initialValues: ChatMessageCRUDType = ChatMessageCreateSchema.required().default();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={ChatMessageCreateSchema}
      validateOnBlur={false}
    >
      {({ isSubmitting, dirty, resetForm }) => (
        <Form>
          <UnexpectedFormErrors expectedErrors={['imageFile', 'text']}/>
          <div className={'bubblerow create'}>
            <div className='bubble py-2 last'>
              { allowImage && <ImageFileDropInput name='imageFile' iconSize={'1.5em'} existingImageUrl='' /> }
              <IonFormikShortTextarea autofocus={true} name='text' placeholder={newMessagePlaceholder} rows={1}/>
            </div>
            <div className='ion-text-right'>
              <IconSendButton disabled={isSubmitting || !dirty} isLoading={isSubmitting}/>
              <IconCancelButton onClick={() => {
                resetForm();
                setEditMessageId('');
              }}/>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

const ChatMessageEditSchema = yup.object({
  text: yup.string().defined().default(''),
  s3ImageName: yup.string().notRequired()
});

export function ChatMessageEditForm ({ message, close, closeDeleted = close, update, del, allowImage, imageIconSize }:
  {
    message: ChatMessageInterface,
    close: () => void,
    closeDeleted?: () => void,
    update: (id: string, text: string, s3ImageName?: string) => Promise<void>,
    del: (id: string) => Promise<void>
    rows?: number,
    allowImage: boolean,
    imageIconSize: '2.5em' | '1.5em'
  }) {
  const { contentRef } = useContext(UserLayoutContext);
  const didShowCount = useKeyboardDidShowCount();

  useEffect(() => {
    // scroll to the top of the message
    const y = document.getElementById(`message${message.id}`)?.offsetTop;
    y && contentRef && contentRef.current && contentRef.current.scrollToPoint(0, y, 500);
  }, [didShowCount, contentRef, message]);

  async function handleSubmit (values: ChatMessageCRUDType & { imageFile?: File }, actions: FormikHelpers<ChatMessageCRUDType>) {
    try {
      if (values.text && values.text.length > 0) {
        if (values.imageFile) {
          try {
            values.s3ImageName = await s3UploadMessageImage(values.imageFile, { shouldResize: 'true' });
          } catch (e) {
            // image upload failed report exception and return from the function early
            reportException(e, 's3UploadMessageImage failed in ChatMessageList ChatMessageEditForm handleSubmit');
            actions.setStatus(e.message || e);
            actions.setSubmitting(false);
            return;
          }
        }
        await update(message.id, values.text, values.s3ImageName);
        actions.setSubmitting(false);
        actions.resetForm();
        close();
      } else {
        const confirmed = window.confirm(
          'Are you sure you want to delete this message?'
        );

        if (confirmed) {
          await del(message.id);
          actions.setSubmitting(false);
          closeDeleted();
        }
      }
    } catch (e) {
      reportException(e, 'update or del failed in ChatMessageList ChatMessageEditForm handleSubmit');
      actions.setStatus(e.message || e);
      actions.setSubmitting(false);
    }
  }

  const initialValues: ChatMessageCRUDType = ChatMessageEditSchema.required().default();
  initialValues.text = message.text;
  initialValues.s3ImageName = message.s3ImageName;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={ChatMessageEditSchema}
    >
      {({ isSubmitting, dirty, setFieldValue, values }) => (
        <Form>
          <UnexpectedFormErrors expectedErrors={['text']}/>
          <div className={'bubblerow mb-1 mine'}>
            <div className='bubble py-2 text-dark w-100'>
              {
                allowImage && <div><ImageFileDropInput
                  name='imageFile'
                  iconSize={imageIconSize}
                  existingImageUrl={values.s3ImageName && message.imageUrl ? message.imageUrl : ''}
                  removeExistingImage={() => setFieldValue('s3ImageName', '')}
                />
                </div>
              }
              <IonFormikShortTextarea autofocus={true} name='text' placeholder='Enter a new message' rows={1}/>
            </div>

            <div className='ion-text-right' >
              {values.text && values.text.length > 0
                ? <IconSendButton tabIndex={-1} disabled={isSubmitting || !dirty} isLoading={isSubmitting} />
                : <IconDeleteButton disabled={isSubmitting || !dirty} isLoading={isSubmitting}/>
              }
              <IconCancelButton onClick={() => {
                close();
              }}/>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
