import { Redirect, Route, useParams } from 'react-router';
import React from 'react';
import { DirectMessageLIst } from './DirectMessageLIst';
import { PostList } from './PostList';
import { DirectMessageChat } from './MessageChat';
import PostDetails from './PostDetails';
import NewPost from './NewPost';
import NewDirectMessage from './NewDirectMessage';
import { Switch } from 'react-router-dom';
import { IonRouterOutletWrapper } from '../../components/RouterOutlet';
import PostNewChat from './PostNewChat';
import { BusinessResourceRefresher } from '../../libs/resourceRefresher';

export default function Messages () {
  const { businessId } = useParams<{ businessId: string }>();

  // IonRouterOutletWrapper for an explanation of this nonsense.
  // Also see ResourceRefresh for more nonsense.
  const routes = [
    { path: '/businesses/:businessId/posts/new/new', name: 'New Post', component: <BusinessResourceRefresher businessId={businessId}><NewPost/></BusinessResourceRefresher> },
    { path: '/businesses/:businessId/posts/:threadId', name: 'Post Detail', component: <BusinessResourceRefresher businessId={businessId}><PostDetails/></BusinessResourceRefresher> },
    { path: '/businesses/:businessId/posts/:threadId/channels', name: 'New Post Direct Message', component: <BusinessResourceRefresher businessId={businessId}><PostNewChat/></BusinessResourceRefresher> },
    { path: '/businesses/:businessId/posts', name: 'Posts', component: <BusinessResourceRefresher businessId={businessId}><PostList/></BusinessResourceRefresher> },
    { path: '/businesses/:businessId/messages/new/new', name: 'New Direct Message', component: <BusinessResourceRefresher businessId={businessId}><NewDirectMessage /></BusinessResourceRefresher> },
    { path: '/businesses/:businessId/messages/:threadId/channels/:channelId', name: 'Chat', component: <BusinessResourceRefresher businessId={businessId}><DirectMessageChat/></BusinessResourceRefresher> },
    { path: '/businesses/:businessId/messages', name: 'Direct Messages', component: <BusinessResourceRefresher businessId={businessId}><DirectMessageLIst/></BusinessResourceRefresher> }
  ];

  return (
    <Switch>
      <Route exact path={routes[0].path} name={routes[0].name}><IonRouterOutletWrapper outletRoutes={routes}/></Route>
      <Route exact path={routes[1].path} name={routes[1].name}><IonRouterOutletWrapper outletRoutes={routes}/></Route>
      <Route exact path={routes[2].path} name={routes[2].name}><IonRouterOutletWrapper outletRoutes={routes}/></Route>
      <Route exact path={routes[3].path} name={routes[3].name}><IonRouterOutletWrapper outletRoutes={routes}/></Route>
      <Route exact path={routes[4].path} name={routes[4].name}><IonRouterOutletWrapper outletRoutes={routes}/></Route>
      <Route exact path={routes[5].path} name={routes[5].name}><IonRouterOutletWrapper outletRoutes={routes}/></Route>
      <Route exact path={routes[6].path} name={routes[6].name}><IonRouterOutletWrapper outletRoutes={routes}/></Route>
      <Redirect to={'/'} />
    </Switch>
  );
}
