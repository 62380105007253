import React, { useEffect } from 'react';
import { useResetter } from 'rest-hooks';
import { Redirect } from 'react-router-dom';

export default function Logout () {
  const resetCache = useResetter();
  useEffect(() => {
    resetCache();
  });
  return (<Redirect to={'/login/password'}/>);
}
