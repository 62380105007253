import React from 'react';
import { Route, Redirect, useLocation, RouteProps } from 'react-router-dom';
import { useAppContext } from '../libs/context';
export interface ProtectedRouteProps extends RouteProps {
  isAuthenticated: boolean;
  isAllowed: boolean;
  restrictedPath: string;
  authenticationPath: string;
}

type AuthRouteProps = Required<Pick<RouteProps, 'component'>> & Omit<RouteProps, 'children' | 'render'>;

export function AuthenticatedRoute ({ component: Component, ...rest }: AuthRouteProps) {
  const { authenticated } = useAppContext();
  const { pathname, search } = useLocation();

  return (
    <Route {...rest}
      render={(props) => authenticated === true
        ? <Component {...props} />
        : <Redirect to={`/login/password?redirect=${pathname}${search}`} />}
    />
  );
}

export function AuthOrUnauthRoute ({ authComponent: AuthComponent, unauthComponent: UnauthComponent, ...rest }:
  { authComponent: React.FunctionComponent, unauthComponent: React.FunctionComponent, path: string }) {
  const { authenticated } = useAppContext();

  return (<Route {...rest} render={() => (authenticated === true) ? <AuthComponent /> : <UnauthComponent /> } />);
}

function querystring (name: string, url = window.location.href) {
  name = name.replace(/[[]]/g, '\\$&');

  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i');
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function UnauthenticatedRoute ({ component: Component, path, exact = false }: { component: React.FunctionComponent; path: string; exact?: boolean}) {
  const redirect = querystring('redirect');
  const { authenticated } = useAppContext();

  return (
    <Route path={path} exact={exact} render={() => (authenticated === true)
      ? <Redirect to={redirect === '' || redirect === null ? '/' : redirect} />
      : <Component /> }
    />
  );
}
