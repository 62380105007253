import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonItem, IonLabel,
  IonList,
  IonListHeader,
  IonTitle,
  IonToolbar,
  IonNote
} from '@ionic/react';
import { UserDropdown } from '../../components/Dropdowns';
import { useHistory, useLocation, useParams } from 'react-router';
import React, { useContext } from 'react';
import { BusinessContext, UserLayoutPage } from '../../containers/UserLayout';
import { useFetcher } from 'rest-hooks';

import { reportException } from '../../libs/errors';
import { CustomerResource } from '../../models/customer';
import { AvatarImage } from '../../components/Avatars';
import { format, parseISO } from 'date-fns';
import { GroupLargeBadge } from '../../components/Groups';
import { Link } from 'react-router-dom';
import { channels } from 'sprancer-shared';
import { useDeleteAlerts } from '../../components/Alerts';
import { getReferrerInfo } from '../../components/Activations';

export default function CustomerDetails () {
  return (
    <UserLayoutPage
      header={<Header/>}
      content={<Content />}
    />
  );
}

function Header () {
  const { businessId } = useParams<{ businessId: string }>();

  return (
    <>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton defaultHref={`/businesses/${businessId}/customers`}/>
        </IonButtons>
        <IonTitle>Customer Details</IonTitle>
        <UserDropdown slot="end" />
      </IonToolbar>
    </>
  );
}

function Content () {
  const { businessId, customerId } = useParams<{ businessId: string, customerId: string }>();
  const { customersById, privGroupsById } = useContext(BusinessContext);

  const location = useLocation();
  useDeleteAlerts(location.pathname); // We're viewing the customer now so delete the "new customer" alert

  // Lookup the group from the context instead of useResource(...).  For some reason this group details page gets
  // called after the Delete Group button is pushed.  Since the resource has been delete useResource blows up.
  const customer = customersById.get(customerId);
  if (!customer) {
    return (<p>Customer Unvailable</p>);
  }

  const customerPrivGroups = customer.groupIds.map((gId) => privGroupsById.get(gId)).filter(g => g?.priv);

  return (<>
    <div className={'d-flex'}>
      <Link className='ml-auto mr-2 pt-2' to={{
        pathname: `/businesses/${businessId}/messages/${channels.genDirectThreadId(customerId)}/channels/${channels.genDirectChannelId(customerId)}`,
        state: { newMessage: true }
      }}>
        <IonButton size='default' color='primary'>
          New Message
        </IonButton>
      </Link>
    </div>
    <IonListHeader lines='inset' className={'mb-2'}>
      Customer
      <IonButton
        className='ml-auto'
        color="primary"
        fill="clear"
        routerLink={`/businesses/${businessId}/customers/${customerId}/editDetails`}
      >Edit</IonButton>
    </IonListHeader>
    <IonList>
      <IonItem lines='none'>
        <AvatarImage slot='start' size='lg' status={customer.status} avatar={customer.avatar} />
        <IonLabel className={'text-wrap'}>
          <h2>{customer.nickname}</h2>
        </IonLabel>
      </IonItem>
      <IonItem lines='none'>
        <IonLabel>
          <p className={'mb-2'}><IonNote>Connected {format(parseISO(customer.createdAt), 'MMMM do, yyyy')}.</IonNote></p>
          { customer.referrer && <p><IonNote>{getReferrerInfo(customer.referrer, customersById)}</IonNote></p> }
        </IonLabel>
      </IonItem>
      <IonItem lines='none'>
        <IonLabel className={'text-wrap'}>
          <h2>Notes</h2>
          <p>{customer.notes || 'None'}</p>
        </IonLabel>
      </IonItem>
      <IonItem lines='none'>
        <IonLabel><h2>Private Groups</h2></IonLabel>
      </IonItem>
      { customerPrivGroups.length === 0
        ? <IonItem lines='none'><IonLabel><IonNote>
            { privGroupsById.size === 0 ? 'No private groups available.' : 'None' }
          </IonNote></IonLabel></IonItem>
        : customerPrivGroups.map(group =>
          group && group.priv && <IonItem key={group.id} lines='none'>
            <GroupLargeBadge group={group}/>
          </IonItem>
        )
      }
    </IonList>
    <IonListHeader lines='inset' className={'mb-2'}>Actions</IonListHeader>
    <IonList>
      <DeleteItem businessId={businessId} customerId={customerId} customerName={customer.nickname}/>
    </IonList>
  </>);
}

function DeleteItem ({ businessId, customerId, customerName }: {businessId: string, customerId: string, customerName: string}) {
  const history = useHistory();
  const del = useFetcher(CustomerResource.deleteShape());

  function handleDelete () {
    const confirmed = window.confirm(
      `Are you sure you want to delete '${customerName}'?`
    );

    if (confirmed) {
      history.replace(`/businesses/${businessId}/customers`, { deleteGroupParams: { businessId, id: customerId } });
      del({ businessId, id: customerId }, undefined).catch((e) => {
        reportException(e, 'del failed in CustomerDetails DeleteItem handleDelete');
        alert(`Failed to delete with error: ${e}`);
      });
    }
  }
  return (
    <IonItem lines='none'><IonButton color='danger' size={'default'} expand={'block'} onClick={handleDelete}>Delete Customer</IonButton></IonItem>
  );
}
