import {
  IonBackButton, IonButton,
  IonButtons,
  IonItem, IonLabel,
  IonTitle,
  IonToolbar, NavContext
} from '@ionic/react';
import { UserDropdown } from '../../components/Dropdowns';
import { useParams } from 'react-router';
import React, { useContext } from 'react';
import { UserLayoutPage } from '../../containers/UserLayout';
import { useFetcher, useResource } from 'rest-hooks';
import { GroupResource } from '../../models/group';
import { Form, Formik, FormikHelpers } from 'formik';
import { reportException } from '../../libs/errors';
import { schemas } from 'sprancer-shared';
import { ColorSelectInput, IonFormikInput, ToggleInput, UnexpectedFormErrors } from '../../components/Forms';
import { TextSaveButton } from '../../components/FormButtons';
import { GroupUpdateType } from 'sprancer-shared/dist/schemas';
import { generateActivationLink } from '../../components/Activations';
import { RefreshIcon } from '../../libs/icons';

export default function GroupDetailsEdit () {
  return (
    <UserLayoutPage
      header={<Header/>}
      content={<Content />}
    />
  );
}

function Header () {
  const { businessId, groupId } = useParams<{ businessId: string, groupId: string }>();

  return (
    <>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton text='Cancel' defaultHref={`/businesses/${businessId}/groups/${groupId}`}/>
        </IonButtons>
        <IonTitle>Edit Group</IonTitle>
        <UserDropdown slot="end" />
      </IonToolbar>
    </>
  );
}

function Content () {
  const { businessId, groupId } = useParams<{ businessId: string, groupId: string }>();
  const { goBack } = useContext(NavContext);

  const group = useResource(GroupResource.detailShape(), { businessId, id: groupId });
  const update = useFetcher(GroupResource.updateShape());

  async function handleSubmit (values: GroupUpdateType, actions: FormikHelpers<GroupUpdateType>) {
    try {
      await update({ businessId: group.businessId, id: group.id }, values);
      actions.setSubmitting(false);
      goBack(`/businesses/${businessId}/groups/${group.id}`);
    } catch (e) {
      reportException(e, 'update failed in GroupDetailsEdit Content handleSubmit');
      actions.setStatus(e.message || e);
      actions.setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={group}
      onSubmit={handleSubmit}
      validationSchema={schemas.GroupUpdateSchema}
    >
      {({ isSubmitting, dirty, values, setFieldValue }) => (
        <Form>
          <UnexpectedFormErrors expectedErrors={['name', 'color']}/>
          <IonFormikInput name='name' autocapitalize={'words'} placeholder={'Name'} type='text'/>
          <ToggleInput name='priv' onValue={true} offValue={false} label={'Private'}/>
          <ColorSelectInput name='color' label={<>Color</>} />
          <IonItem lines={'none'}><IonLabel>New Customer Link</IonLabel></IonItem>
          <IonItem>
            {group.priv
              ? <p className={'text-break'}>
                <div className={'d-flex'}>
                  <span className={'my-auto mr-2'}>
                    {values.resetActiviationSecretId
                      ? <><del>{generateActivationLink(group)}</del><p><em>Save to generate new link</em></p></>
                      : group.activationSecretId ? generateActivationLink(group) : <em>None</em>}
                  </span>
                  <IonButton
                    className='mr-1'
                    color='danger'
                    fill={values.resetActiviationSecretId ? 'outline' : 'solid'}
                    onClick={() => {
                      const confirmed = values.resetActiviationSecretId || !group.activationSecretId || window.confirm(
                        'Are you sure you want to recreate this link?  The existing link will stop working.'
                      );
                      if (confirmed) {
                        setFieldValue('resetActiviationSecretId', !values.resetActiviationSecretId);
                      }
                    }}>
                    <RefreshIcon size='2em'/>
                  </IonButton>
                </div>
              </p>
              : <p className={'text-muted'}>Public groups use the shared public activation link.</p>
            }
          </IonItem>
          <IonItem lines="none" className='text-center'>
            <IonLabel>
              <TextSaveButton disabled={isSubmitting || !dirty} isLoading={isSubmitting} />
            </IonLabel>
          </IonItem>
        </Form>
      )}
    </Formik>
  );
}
