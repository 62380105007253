import { useParams } from 'react-router';
import React, { useContext, useMemo, useState } from 'react';
import {
  IonBackButton, IonBadge,
  IonButtons, IonCol, IonGrid, IonItem, IonRow,
  IonTitle,
  IonToolbar,
  IonItemDivider,
  IonLabel
} from '@ionic/react';
import { UserDropdown } from '../../components/Dropdowns';
import { BusinessContext, UserLayoutPage } from '../../containers/UserLayout';
import { MessageResource, PostThread } from '../../models/message';
import { AlertsContext } from '../../components/Alerts';
import { channels } from 'sprancer-shared';
import { AvatarImage } from '../../components/Avatars';
import { ReactionsIcons, UserIcon, UsersIcon } from '../../libs/icons';
import { GroupBadges } from '../../components/Groups';
import { calculateCustomerIds } from './messageHelpers';
import { MissingSomethingWarning } from '../../components/Warnings';
import { useFetcher } from 'rest-hooks';
import { ChatMessageCreateToolbar, ChatMessageList, ChatMessagePageContext } from '../../components/ChatMessageList';

export default function PostDetails () {
  return (
    <UserLayoutPage
      header={<Header/>}
      content={<Content/>}
    />
  );
}

function Header () {
  const { businessId } = useParams<{ businessId: string }>();

  return (
    <IonToolbar>
      <IonButtons slot="start">
        <IonBackButton defaultHref={`/businesses/${businessId}/posts`}/>
      </IonButtons>
      <IonTitle>Post Details</IonTitle>
      <UserDropdown slot="end"/>
    </IonToolbar>
  );
}

function Content () {
  const { businessId, threadId } = useParams<{ businessId: string, threadId: string }>();
  const { groupToCustomerIds, postThreadsById } = useContext(BusinessContext);
  const thread = postThreadsById.get(threadId);

  const [editMessageId, setEditMessageId] = useState('');

  if (!thread || thread.isDirect()) {
    return (<p className='text-muted'>This message is no longer available. Messages expire after 30 days.</p>);
  }

  const newMessageId = `newMessage${threadId}${thread.threadChannelId()}`;

  const postMessages = thread && !thread.isDirect() ? thread.messages || [] : [];
  const customerIdCount = calculateCustomerIds(thread.firstMsg.groupIds, groupToCustomerIds).length;

  return (<ChatMessagePageContext.Provider value={{ newMessageId, newMessagePlaceholder: 'Enter a new group message', editMessageId, setEditMessageId }} >
      <ThreadHeader message={thread.firstMsg} customerIdCount={customerIdCount} />
      <GroupMessageList thread={thread} chatMessages={postMessages} businessId={businessId}/>
      <ChatMessageCreateToolbar placeholder={'Group message...'} />
      <IonItemDivider><IonLabel>Direct Messages</IonLabel></IonItemDivider>
      <ThreadList thread={thread} customerIdCount={customerIdCount} />
      <div className='text-muted small ion-text-center mb-2'>Messages expire after 30 days.</div>
    </ChatMessagePageContext.Provider>
  );
}

function ThreadHeader ({ message, customerIdCount }:
  {
    message: MessageResource;
    customerIdCount: number;
  }) {
  const { pubGroupsById, privGroupsById } = useContext(BusinessContext);

  const groups = message.groupIds.map(groupId => pubGroupsById.get(groupId) || privGroupsById.get(groupId));

  return (<>
    <IonItemDivider><IonLabel>Group Messages</IonLabel></IonItemDivider>
    <IonItem>
      <IonLabel>
        <span className='pr-1'>
          <UsersIcon/> {customerIdCount}
        </span>
        { groups && <GroupBadges groups={groups}/> }
      </IonLabel>
    </IonItem>
  </>);
}

function GroupMessageList ({ thread, chatMessages, businessId }:
  {
    thread: PostThread;
    chatMessages: MessageResource[];
    businessId: string;
  }) {
  const update = useFetcher(MessageResource.updateShape());
  const del = useFetcher(MessageResource.deleteShape());
  const create = useFetcher(MessageResource.messageCreateShape());

  return (
    <ChatMessageList
      currentConnectionOrBusinessId={businessId}
      sortedMessages={chatMessages}
      allowImage={true}
      update={(id: string, text: string, s3ImageName?: string) => update({ id, businessId }, { text, s3ImageName })}
      del={(id: string) => del({ id, businessId }, undefined)}
      create={(text: string, s3ImageName?: string) => create({ businessId },
        { threadId: thread.threadId(), channelId: thread.threadChannelId(), groupIds: thread.threadGroupIds(), text, ...s3ImageName && { s3ImageName } }, [
          [MessageResource.listShape(), { businessId }, (id, ids) => [...(ids || []), id]]
        ])}
    />
  );
}

function ThreadList ({ thread, customerIdCount }:
  {
    thread: PostThread;
    customerIdCount: number;
  }) {
  const { businessId } = useParams<{ businessId: string }>();
  const { pathToAlertIds } = useContext(AlertsContext);
  const { customersById, dmThreadsByIdAndChannelId } = useContext(BusinessContext);

  const customerIdToReactionIcons = useMemo(() => {
    const customerIdToIcons = new Map<string, JSX.Element[]>();
    ReactionsIcons.forEach(([r, icon]) => {
      (thread.firstMsg.reactionList[r] || []).forEach((customerId) => {
        const customerReactions = customerIdToIcons.get(customerId);
        if (customerReactions) {
          customerReactions.push(icon);
        } else {
          customerIdToIcons.set(customerId, [icon]);
        }
      });
    });
    return customerIdToIcons;
  }, [thread.firstMsg]);

  const customerIdsToChannelIds = new Map<string, string>();
  for (const channelId of thread.dmChannelIds) {
    customerIdsToChannelIds.set(channels.getCustomerIdFromChannel(channelId) || '', channelId);
  }

  for (const customerId of Array.from(customerIdToReactionIcons.keys())) {
    customerIdsToChannelIds.set(customerId, channels.genDirectChannelId(customerId));
  }

  if (customerIdCount === 0 && customerIdsToChannelIds.size === 0) {
    return (<MissingSomethingWarning warningIcon={false} title={'No Customers'} />);
  }

  return (
    <>
      {Array.from(customerIdsToChannelIds.entries(), ([customerId, channelId]) => {
        const customer = customersById.get(customerId);

        const chatPath = `/businesses/${businessId}/messages/${thread.threadId()}/channels/${channelId}`;
        const reactionIcons = customerIdToReactionIcons.get(customerId)?.map((icon, i) => <span key={i}
                                                                                                className='pl-1'>{icon}</span>);
        const alertCount = pathToAlertIds.get(chatPath)?.length;

        const lastReply = dmThreadsByIdAndChannelId.get(thread.threadId() + channelId)?.lastMsg;

        return (
          <IonItem key={customerId} routerLink={chatPath}>
            {alertCount && <IonBadge slot='end' color={'danger'}>{alertCount}</IonBadge>}
            <IonGrid className='px-0 overflow-hidden'>
              <IonRow>
                <IonCol className={'pb-0'}>
                  <div className='d-flex'>
                    { customer
                      ? <>
                        <div className='mr-2'><AvatarImage size='xs' className='avatar-xs' avatar={customer.avatar}/></div>
                        <div className='my-auto mr-1 text-truncate font-weight-bold small'>{customer.nickname}</div>
                      </>
                      : <div className='font-weight-bold small'><UserIcon/> DELETED CUSTOMER</div>
                    }
                    {reactionIcons && <div className='mt-auto text-nowrap mr-2'>{reactionIcons}</div>}
                  </div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className='text-muted small py-0'>{lastReply && lastReply.formattedDate()}</IonCol>
              </IonRow>
              <IonRow>
                <IonCol className='text-truncate text-muted'>{lastReply && lastReply.text}</IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>
        );
      })}
      <IonItem routerLink={`/businesses/${businessId}/posts/${thread.threadId()}/channels`} detail={true}>
        <IonLabel className={'text-muted ml-2'}>{'New Direct Message'}</IonLabel>
      </IonItem>
    </>
  );
}
