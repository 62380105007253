import React, { Component } from 'react';
import { UserNotFoundError } from '../models/user';
import { Redirect } from 'react-router-dom';

export default class UserErrorBoundary extends Component {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public state: { error: any } = {
    error: null
  };

  public static getDerivedStateFromError (error: Error & { cause?: Error }) {
    if (error.cause instanceof UserNotFoundError) {
      return { error };
    }
  }

  public render () {
    if (this.state.error) {
      return (<Redirect to='/profileCreate' />);
    }

    return this.props.children;
  }
}
