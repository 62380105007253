import React from 'react';

export default class NotFound404ErrorBoundary extends React.Component<
  React.PropsWithChildren<{ fallback: React.ReactNode }>,
  { error?: Error }
  > {
  fallback: React.ReactNode = <>Not Found</>;

  constructor (props: { fallback: React.ReactNode, children?: React.ReactNode; } | Readonly<{ fallback: React.ReactNode, children?: React.ReactNode; }>) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError (error: Error & { status?: number }) {
    if (error.status === 404) {
      return { error };
    }
  }

  public render () {
    if (this.state.error) {
      return (this.props.fallback);
    }

    return this.props.children;
  }
}
