import { GroupResource } from '../models/group';
import {
  IonBadge,
  IonItem,
  IonLabel,
  IonCheckbox,
  IonNote,
  IonRadioGroup,
  IonRadio,
  IonSelect,
  IonSelectOption
} from '@ionic/react';
import React from 'react';
import { PrivateGroupIcon, UserIcon } from '../libs/icons';
import { useField } from 'formik';
import * as lodash from 'lodash';

export function GroupCheckInput ({ name, label, disabledGroupIds, groupsById, groupToCustomerIds }:
  {name: string; label?: string, disabledGroupIds?: Set<string>, groupsById: Map<string, GroupResource>, groupToCustomerIds?: Map<string, Set<string>> }) {
  const [, meta] = useField({ name, type: 'checkbox' });

  return (<>
    <IonItem lines={'none'}>
      <IonLabel>{label}
        {!!(meta.error && meta.touched) && <p><IonNote color="danger" className="ion-padding-start"><small>{meta.error}</small></IonNote></p> }
      </IonLabel>
    </IonItem>
    { Array.from(groupsById.values(), group => (
      <GroupCheckItem key={group.id} name={name} group={group} disabledGroupIds={disabledGroupIds} groupsById={groupsById} groupToCustomerIds={groupToCustomerIds} />
    ))}
  </>);
}

function GroupCheckItem ({ name, group, disabledGroupIds, groupsById, groupToCustomerIds }:
  { name: string, group: GroupResource, disabledGroupIds?: Set<string>, groupsById: Map<string, GroupResource>, groupToCustomerIds?: Map<string, Set<string>> }) {
  const [field, { value }, { setValue }] = useField({ name, value: group.id, type: 'checkbox' });

  // eslint-disable-next-line unused-imports/no-unused-vars-ts
  const { onChange: _, ...fieldRest } = field; // we use a custom onChange handler

  return (
    <IonItem lines={'none'} className={'p-0'}>
      <IonLabel className='flex-wrap'>
        <GroupLargeBadge group={group} mw100={true}/>
      </IonLabel>
      {groupToCustomerIds && <span slot='end' className='text-small text-muted text-nowrap'> <UserIcon/> {groupToCustomerIds.get(group.id)?.size || 0}</span> }
      <IonCheckbox
        slot="start"
        {...field}
        disabled={disabledGroupIds && disabledGroupIds.has(group.id)}
        onIonChange={(e) => {
          if (e.detail.checked) {
            let newValue = value.concat([group.id]);
            // sort the groupIds by groupName so they show up consistently when listed in the ui
            newValue = lodash.sortBy(newValue, (groupId) => (groupsById.get(groupId)?.name || ''));
            setValue(newValue);
          } else {
            setValue(value.filter((i: string) => i !== group.id));
          }
        }}
      />
    </IonItem>
  );
}

export function GroupRadioInput ({ name, label, groupsById }:
  {name: string; label?: string, groupsById: Map<string, GroupResource> }) {
  const [{ onChange, ...field }] = useField(name);
  return (<>
    <IonItem lines={'none'}>
      {label && <IonLabel>{label}</IonLabel>}
    </IonItem>
    <IonItem lines={'full'}>
      <IonRadioGroup onIonChange={onChange} {...field} >
        { Array.from(groupsById.values(), group => (
          <IonItem key={group.id} lines={'none'} >
            <IonLabel className='flex-wrap'>
              <GroupLargeBadge group={group} mw100={true}/>
            </IonLabel>
            <IonRadio slot="start" value={group.id}/>
          </IonItem>
        ))}
      </IonRadioGroup>
    </IonItem>
  </>);
}

export function GroupSelect ({ name, placeholder, groupsById }:
  {name: string, placeholder: string, groupsById: Map<string, GroupResource> }) {
  const [{ onChange, ...field },, { setValue }] = useField(name);
  return (
    <IonSelect
      className='mw-100'
      style={{ '--placeholder-opacity': '.5' }}
      onIonChange={e => {
        if (e.detail.value === 'none') {
          setValue('');
        } else {
          onChange(e);
        }
      }} {...field}
      interfaceOptions={{
        header: 'Private Group',
        subHeader: '(Optional)'
      }}
      placeholder={placeholder} >
      <IonSelectOption value={'none'}><div className={'text-muted'}>None</div></IonSelectOption>
      { Array.from(groupsById.values(), group => (
        <IonSelectOption key={group.id} value={group.id}>{group.name}</IonSelectOption>
      ))}
    </IonSelect>
  );
}

export function GroupBadges ({ groups }: {groups: (GroupInterface | undefined)[] }) {
  return (<>{groups.map(g => g && <IonBadge key={g.id} className={`${g.color} small mw-75 mr-1 mb-1 text-truncate text-light`}>{g.name}{g.priv && <PrivateGroupIcon className={'ml-1'} />}</IonBadge>)}</>);
}

export type GroupInterface = {
  id: string;
  color: string;
  name: string;
  priv: boolean;
}

export function GroupLargeBadge ({ group, mw100 = false, disabled = false }: {group: GroupInterface, mw100?: boolean, disabled?: boolean}) {
  return (<IonBadge className={`${disabled ? 'bg-light text-muted border' : group.color + ' text-light'} ${mw100 ? 'mw-100' : ''} my-auto px-4 py-2 text-wrap`}>
    {group.name}{group.priv && <PrivateGroupIcon className={'ml-1'} />}
  </IonBadge>);
}
