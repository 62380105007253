import * as yup from 'yup';
import { Formik, FormikHelpers, Form } from 'formik';
import { SaveButton } from '../../components/FormButtons';
import React from 'react';
import * as Auth from '../../libs/auth';
import { reportException } from '../../libs/errors';
import { PasswordFormLabelIcon } from '../../libs/icons';
import { IonFormikInput, UnexpectedFormErrors } from '../../components/Forms';

const ConfirmationSchema = yup.object({
  confirmationCode: yup.string().required().default('')
});

export function SignupConfirmationForm ({ email, onConfirmationSuccess }:
  { email: string, onConfirmationSuccess: () => Promise<void>}) {
  async function handleSubmit (values: { confirmationCode: string; }, actions: FormikHelpers<{ confirmationCode: string; }>) {
    try {
      await Auth.confirmSignUp(email, values.confirmationCode);
      await onConfirmationSuccess();
    } catch (e) {
      actions.setSubmitting(false);
      reportException(e, 'handleSubmit failed in SignupConfirmationForm');

      if (e instanceof Auth.CodeMismatchAuthError) {
        actions.setStatus(<>Invalid verification code provided, please try again.</>);
      } else {
        actions.setStatus(e.message);
      }
    }
  }

  async function resendConfirmationCode (e: React.MouseEvent, setStatus: (status?: unknown) => void) {
    e.preventDefault();
    try {
      await Auth.resendSignUp(email);
      setStatus(<>Confirmation code sent to {email}.</>);
    } catch (e) {
      reportException(e, 'handleSubmit failed in SignupConfirmationForm resendConfirmationCode');
      setStatus(e.message);
    }
  }

  const initialValues = ConfirmationSchema.required().default();
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={ConfirmationSchema}
    >
      {({ isSubmitting, dirty, setStatus }) => (
        <Form>
          <UnexpectedFormErrors expectedErrors={['confirmationCode']}/>
          <p className='ion-padding-bottom'>Please check your email a confirmation code or <a href='/#' onClick={(e) => resendConfirmationCode(e, setStatus)}>send a new code</a>.</p>
          <div className='ion-padding-bottom'>
            <IonFormikInput name='confirmationCode' label={<PasswordFormLabelIcon />} type='tel' placeholder='Confirmation Code'/>
          </div>
          <SaveButton
            expand={'block'}
            disabled={isSubmitting || !dirty}
            isLoading={isSubmitting}
            text={<div>Create Account</div>}
            loadingText='Verifying…'
            submitOnEnter={true}
          />
        </Form>
      )}
    </Formik>
  );
}
