import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonItem, IonLabel,
  IonList,
  IonListHeader,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import { UserDropdown } from '../../components/Dropdowns';
import { useHistory, useParams } from 'react-router';
import React, { useContext } from 'react';
import { BusinessContext, UserLayoutPage } from '../../containers/UserLayout';
import { useFetcher, useResource } from 'rest-hooks';
import { GroupResource } from '../../models/group';
import { generateActivationLink, generatePublicActivationLink } from '../../components/Activations';
import { reportException } from '../../libs/errors';
import { BusinessResource } from '../../models/business';
import { Link } from 'react-router-dom';

export default function GroupDetails () {
  return (
    <UserLayoutPage
      header={<Header/>}
      content={<Content />}
    />
  );
}

function Header () {
  const { businessId } = useParams<{ businessId: string }>();

  return (
    <>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton defaultHref={`/businesses/${businessId}/customers`}/>
        </IonButtons>
        <IonTitle>Group Details</IonTitle>
        <UserDropdown slot="end" />
      </IonToolbar>
    </>
  );
}

function Content () {
  const { businessId, groupId } = useParams<{ businessId: string, groupId: string }>();
  const business = useResource(BusinessResource.detailShape(), { id: businessId });

  const { pubGroupsById, privGroupsById } = useContext(BusinessContext);

  // Lookup the group from the context instead of useResource(...).  For some reason this group details page gets
  // called after the Delete Group button is pushed.  Since the resource has been delete useResource blows up.
  const group = pubGroupsById.get(groupId) || privGroupsById.get(groupId);
  if (!group) {
    return (<p>Group Unvailable</p>);
  }

  return (<>
    <IonListHeader lines='inset'>Group
      <IonButton
        className='ml-auto'
        color="primary"
        fill="clear"
        routerLink={`/businesses/${businessId}/groups/${groupId}/editDetails`}
      >Edit</IonButton>
    </IonListHeader>
    <IonList>
      <IonItem lines='none'>
        <IonLabel>
          <h2>Name</h2>
          <p>{group.name}</p>
        </IonLabel>
      </IonItem>
      <IonItem lines='none'>
        <IonLabel>
          <h2>Private</h2>
          <p>{group.priv ? 'true' : 'false'}</p>
        </IonLabel>
      </IonItem>
      <IonItem lines='none'>
        <IonLabel>
          <h2>Color</h2>
          <p><span className={`px-4 ${group.color}`}>&nbsp;</span></p>
        </IonLabel>
      </IonItem>
    </IonList>
    <IonListHeader lines='inset'>New Customer Link</IonListHeader>
    <IonList>
      <ActivationLinkItem business={business} group={group}/>
    </IonList>
    <IonListHeader lines='inset' className={'mb-2'}>Actions</IonListHeader>
    <IonList>
      <DeleteItem businessId={businessId} groupId={groupId} groupName={group.name}/>
    </IonList>
  </>);
}

function ActivationLinkItem ({ business, group }: { business: BusinessResource, group: GroupResource }) {
  const link = group.priv ? generateActivationLink(group) : generatePublicActivationLink(business);

  return (
    <IonItem lines='none'>
      <div>
      <p className='text-muted small'>Share this link to connect new customers. They will be added to this group.</p>
      <p className='text-break'> {link || <em>None</em>}</p>
        <div>
          <Link
            className='mr-2 pt-2'
            to={{
              pathname: `/businesses/${business.id}/customers/new/new`,
              state: group.priv ? { privateGroupId: group.id } : {}
            }} >
            <IonButton size='default' expand='block' color='primary'>
              New Customer
            </IonButton>
          </Link>
        </div>
      </div>
    </IonItem>
  );
}

function DeleteItem ({ businessId, groupId, groupName }: {businessId: string, groupId: string, groupName: string}) {
  const history = useHistory();
  const del = useFetcher(GroupResource.deleteShape());

  function handleDelete () {
    const confirmed = window.confirm(
      `Are you sure you want to delete '${groupName}'?`
    );

    if (confirmed) {
      history.replace(`/businesses/${businessId}/customers`, { deleteGroupParams: { businessId, id: groupId } });
      del({ businessId, id: groupId }, undefined).catch((e) => {
        reportException(e, 'del failed in GroupDetails DeleteItem handleDelete');
        alert(`Failed to delete with error: ${e}`);
      });
    }
  }
  return (
    <IonItem lines='none'><IonButton color='danger' size={'default'} expand={'block'} onClick={handleDelete}>Delete Group</IonButton></IonItem>
  );
}
