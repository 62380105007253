import { BaseBusinessResource } from './api';
import { schemas } from 'sprancer-shared';

const DELIMETER = 'Z';
export type ReferrerType =
  'C' | // custom
  'U'; // userid

export interface GroupResource extends schemas.GroupType {}
export class GroupResource extends BaseBusinessResource {
  constructor (init?: schemas.GroupCreateType) {
    super();
    Object.assign(this, init);
  }

  pk (): string | undefined {
    return this.id;
  }

  activationSecretIdWithReferrer (referrerType?: ReferrerType, referrer?: string) {
    if (referrer) {
      return this.activationSecretId + DELIMETER + referrerType + referrer;
    } else {
      return this.activationSecretId;
    }
  }

  static urlRoot = '/groups';
}
