import React from 'react';
import { FiBookOpen, FiHeart, FiSmile, FiThumbsDown, FiThumbsUp } from 'react-icons/fi';
import { schemas } from 'sprancer-shared';
export {
  // Button Icons
  FiPlus as CreateIcon,
  FiCheck as SaveIcon,
  FiCheck as SendIcon,
  FiTrash2 as DeleteIcon,
  FiX as CancelIcon,
  FiX as CloseIcon,

  // Form Label Icons
  FiShoppingBag as BusinessNameFormLabelIcon,
  FiMail as EmailFormLabelIcon,
  FiUser as NameFormLabelIcon,
  FiLock as PasswordFormLabelIcon,

  // DropDownIcons
  FiBell as AlertsIcon,
  FiEdit2 as EditIcon,
  FiLock as LogoutIcon,
  FiMenu as MenuIcon,
  FiThumbsUp as AddReactionIcon,
  FiSettings as SettingsIcon,
  FiCornerUpLeft as ReplyIcon,

  // Nav Icons
  FiBell as NotificationsIcon,
  FiHexagon as AccountsNavIcon,
  FiHome as BusinessDashboadNavIcon,
  FiHome as HomeIcon,
  FiMail as MessagesNavIcon,
  FiMessageSquare as DirectMessagesNavIcon,
  FiMail as PostsNavIcon,
  FiSettings as SettingsNavIcon,
  FiUser as ProfileNavIcon,
  FiUsers as GroupsNavIcon,
  FiUser as CustomersNavIcon,
  FiArrowLeft as BackIcon,
  FiCreditCard as BusinessNavIcon,

  // Dashboard Icons
  FiMail as PostsDashboardIcon,
  FiMessageSquare as DirectMessagesDashboardIcon,
  FiUsers as CustomersDashboardIcon,

  // Other Icons

  FiAlertTriangle as WarningIcon,
  FiChevronDown as ExpandDownIcon,
  FiChevronUp as ExpandUpIcon,
  FiCircle as UnreadItemIcon,
  FiChevronRight as DetailsIcon,
  FiCode as ReferrerCodeIcon,
  FiCopy as CopyIcon,
  FiCamera as ImageIcon,
  FiLock as PrivateGroupIcon,
  FiMail as MessagesIcon,
  FiMail as TokenSentIcon,
  // FiMoreHorizontal as MoreIcon,
  FiLock as PasswordIcon,
  FiPlus as NewItemIcon,
  FiMessageSquare as DirectMessagesIcon,
  FiRefreshCw as RefreshIcon,
  FiShare as ShareIcon,
  FiUser as UserIcon,
  FiUserPlus as CreateAccountIcon,
  FiUsers as UsersIcon,
  FiCloudDrizzle as ErrorIcon

} from 'react-icons/fi';

export {
  CgMoreO as MoreIcon
} from 'react-icons/cg';

export {
  // Non Feather-Icons
  HiOutlineQrcode as QRCodeIcon
} from 'react-icons/hi';

export const ReactionsIcons: [schemas.ReactionType, JSX.Element][] = [
  ['read', <FiBookOpen key='read' />],
  ['smile', <FiSmile key='read'/>],
  ['thumbsUp', <FiThumbsUp key='read'/>],
  ['thumbsDown', <FiThumbsDown key='read'/>],
  ['heart', <FiHeart key='read' />]
];
