import React from 'react';
import { UserLayoutPage } from '../../containers/UserLayout';
import { IonButtons, IonTitle, IonToolbar, IonMenuButton } from '@ionic/react';
import { UserDropdown } from '../../components/Dropdowns';
import RedeemCodeForm from './RedeemCodeForm';

export default function UserRedeemCode () {
  return (
    <UserLayoutPage
      header={<Header/>}
      content={<Content/>}
    />
  );
}

function Header () {
  return (
    <IonToolbar>
      <IonButtons slot="start">
        <IonMenuButton autoHide={true}/>
      </IonButtons>
      <IonTitle>Account</IonTitle>
      <UserDropdown slot="end"/>
    </IonToolbar>
  );
}

function Content () {
  return (<div className={'px-3'}>
    <h4 className={'text-muted mb-4'}>Sprancer is currently invitation only.  If you&apos;ve received a code enter it now:</h4>
    <div className={'mb-4'}>
      <RedeemCodeForm />
    </div>
  </div>);
}
