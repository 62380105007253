import React from 'react';
import { useResource } from 'rest-hooks';
import { CustomerResource } from '../models/customer';
import { MessageResource } from '../models/message';

const DATA_EXPIRY_LENGTH = 6 * 60 * 1000; // six minutes

// We poll for alerts, and when we recieve a new alert we are supposed to download the new resource.  But it seems like
// there is a race condition somewhere because sometimes alerts can show up on a mobile device but the message isn't
// downloaded.
// Messages/alerts need to be redesigned anyway, so for now I'm putting in this 'saftey valve' which will try to refetch
// messages and customers at least every 6 minutes (if the user is clicking around anyway).

export function BusinessResourceRefresher ({ businessId, children }: { businessId: string, children: React.ReactNode }) {
  useResource(CustomerResource.customExpiryListShape({ dataExpiryLength: DATA_EXPIRY_LENGTH }), { businessId });
  useResource(MessageResource.customExpiryListShape({ dataExpiryLength: DATA_EXPIRY_LENGTH }), { businessId });
  return <>{children}</>;
}
