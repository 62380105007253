import { useLocation, useParams } from 'react-router';
import { MessageResource } from '../../models/message';
import React, { useContext, useEffect, useState } from 'react';
import { AvatarWithName } from '../../components/Avatars';
import { useDeleteAlerts } from '../../components/Alerts';
import {
  IonBackButton,
  IonButtons,
  IonTitle,
  IonToolbar,
  IonRow,
  IonItem, IonLabel
} from '@ionic/react';
import { ChatMessageCreateToolbar, ChatMessageList, ChatMessagePageContext } from '../../components/ChatMessageList';
import { useFetcher } from 'rest-hooks';
import { UserDropdown } from '../../components/Dropdowns';
import { BusinessContext, UserLayoutContext, UserLayoutPage } from '../../containers/UserLayout';
import { arrays, channels } from 'sprancer-shared';

type PathParams = { businessId: string; threadId: string; channelId: string; };

export function DirectMessageChat () {
  const { threadId, channelId } = useParams<PathParams>();
  const newMessageId = `newMessage${threadId}${channelId}`;
  const [editMessageId, setEditMessageId] = useState('');

  return (
    <ChatMessagePageContext.Provider value={{ newMessageId, newMessagePlaceholder: 'Enter a new message', editMessageId, setEditMessageId }} >
      <UserLayoutPage
        header={<DirectMessageHeader/>}
        content={<Content/>}
        footer={<Footer/>}
      />
    </ChatMessagePageContext.Provider>
  );
}

function DirectMessageHeader () {
  const { businessId } = useParams<PathParams>();

  return (
    <IonToolbar>
      <IonButtons slot="start">
        <IonBackButton defaultHref={`/businesses/${businessId}/messages`}/>
      </IonButtons>
      <IonTitle>Direct Message</IonTitle>
      <UserDropdown slot="end" />
    </IonToolbar>
  );
}

function Content () {
  const { businessId, threadId, channelId } = useParams<PathParams>();
  const customerId = channels.getCustomerIdFromChannel(channelId) || '';

  const { customersById, postThreadsById, dmThreadsByIdAndChannelId } = useContext(BusinessContext);
  const postThread = postThreadsById.get(threadId);
  const dmThread = dmThreadsByIdAndChannelId.get(threadId + channelId);
  const msgCount = (postThread?.messages.length || 0) + (dmThread?.messages.length || 0);
  const location = useLocation();
  useDeleteAlerts(location.pathname); // We're reading the message now so delete the "unread" alerts

  const { contentRef } = useContext(UserLayoutContext);
  // scroll to the bottom if there is at least one reply.  This means we won't scroll to the bottom if it is just a
  // really long first message.
  useEffect(() => {
    msgCount > 0 && contentRef && contentRef.current && contentRef.current.scrollToBottom(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentRef]);

  if (msgCount === 0 && !channels.isDirectThreadId(threadId)) {
    return (<p className='text-muted'>This message is no longer available.  Messages expire after 30 days.</p>);
  }

  const customer = customersById.get(customerId);

  return (
    <div>
      <div className='mb-2'>
        <IonRow className='ion-justify-content-center'>
          <IonItem lines='none'>{
            customer
              ? <AvatarWithName avatar={customer.avatar} name={customer.nickname}/>
              : <IonLabel className={'text-muted text-wrap'}>DELETED CUSTOMER</IonLabel>
          }</IonItem>
        </IonRow>
        <MessageChatList
          chatMessages={dmThread?.messages || []}
          postMessages={postThread?.messages || []}
          businessId={businessId}
        />
      </div>
      <div className='text-muted small ion-text-center mb-2'>Messages expire after 30 days.</div>
    </div>
  );
}

function MessageChatList ({ chatMessages, postMessages, businessId }:
  {
    chatMessages: MessageResource[];
    postMessages: MessageResource[];
    businessId: string;
  }) {
  const { threadId, channelId } = useParams<PathParams>();

  const update = useFetcher(MessageResource.updateShape());
  const del = useFetcher(MessageResource.deleteShape());
  const create = useFetcher(MessageResource.messageCreateShape());

  return (
    <ChatMessageList
      currentConnectionOrBusinessId={businessId}
      sortedMessages={arrays.mergeSortedBy(chatMessages, postMessages, 'createdAt')}
      uneditableMessageIds={postMessages.length > 0 ? new Set(postMessages.map(m => m.id)) : undefined}
      allowImage={true}
      update={(id: string, text: string, s3ImageName?: string) => update({ id, businessId }, { text, s3ImageName })}
      del={(id: string) => del({ id, businessId }, undefined)}
      create={(text: string, s3ImageName?: string) => create({ businessId },
        { threadId, channelId, groupIds: [], text, ...s3ImageName && { s3ImageName } }, [
          [MessageResource.listShape(), { businessId }, (id, ids) => [...(ids || []), id]]
        ])}
    />
  );
}

function Footer () {
  return (
    <>
      <ChatMessageCreateToolbar />
    </>
  );
}
