import {
  Plugins,
  PushNotificationActionPerformed,
  PushNotificationToken
} from '@capacitor/core';
import { isPlatform } from '@ionic/react';
import { reportException } from './errors';
import { useFetcher } from 'rest-hooks';
import { useEffect } from 'react';
import { UserPushDeviceResource } from '../models/userPushDevice';
import { UserResource } from '../models/user';
import { constants, dates, schemas } from 'sprancer-shared';
import * as lodash from 'lodash';
import { parseISO, subDays } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { AlertResource } from '../models/alert';

const { Device, PushNotifications, Storage } = Plugins;

const CURRENT_TOKEN_STORAGE_KEY = 'UserPushDeviceToken';

export function usePushNotifications (user: UserResource) {
  const history = useHistory();

  const userId = user.userId;
  const pushEnabled = user.pushEnabled === true || user.pushEnabled === undefined;

  const fetchPushDeviceList = useFetcher(UserPushDeviceResource.listShape(), true);
  const createPushDevice = useFetcher(UserPushDeviceResource.createShape(), true);
  const patchPushDevice = useFetcher(UserPushDeviceResource.partialUpdateShape(), true);
  const deletePushDevice = useFetcher(UserPushDeviceResource.deleteShape(), true);
  const date = dates.newISODate(); // add the date string to the effect to make sure the effect executes at least once a day

  const refetchAlerts = useFetcher(AlertResource.listShape(), true);

  useEffect(() => {
    // push notifications only work in phone apps.
    if (pushEnabled && isPlatform('hybrid') && (isPlatform('ios') || isPlatform('android'))) {
      // register the listeners
      const registrationHandler = PushNotifications.addListener('registration', (token: PushNotificationToken) => {
        let thisDeviceToken = token.value;
        if (isPlatform('ios')) {
          thisDeviceToken = thisDeviceToken.toLowerCase();
        }

        fetchPushDeviceList({ userId }).then(async (pushDevices: UserPushDeviceResource[]) => {
          const thisDevice = pushDevices.find(device => device.token === thisDeviceToken);
          if (!thisDevice) {
            // the token for this device isn't already registered with the backend.  do it now.

            try {
              // first, check if we have any stored token already and delete it:
              await deleteStoredPushDevice({ userId, tokenToKeep: thisDeviceToken }, deletePushDevice);

              // next store and create the new token:
              await Storage.set({ key: CURRENT_TOKEN_STORAGE_KEY, value: thisDeviceToken });
            } catch (e) {
              // Unexpected but not critical.  Report and ignore.
              reportException(e, 'deleteStoredPushDevice failed in pushNotifications usePushNotifications useEffect');
            }

            // limit the number of registered devices to 5 by getting rid of any excess no
            if (pushDevices.length >= 5) {
              const sortedPushDevices = lodash.sortBy(pushDevices, 'updatedAt');
              // these are sorted oldeset first, so delete everything until we have 4 left in the array.
              for (let i = 0; i < sortedPushDevices.length - 4; i++) {
                deletePushDevice({ userId: userId, token: pushDevices[i].token }, undefined).catch(e => {
                  // The should never fail.  Report and ignore.
                  reportException(e, 'deletePushDevice failed in pushNotifications usePushNotifications useEffect');
                });
              }
            }

            const createBody: schemas.UserPushDeviceCreateType = {
              token: thisDeviceToken,
              deviceName: await calcDeviceName(),
              app: 'biz',
              platform: calcPlatform()
            };
            createPushDevice({ userId }, createBody, [
              [UserPushDeviceResource.listShape(), { userId }, (id, ids) => [...(ids || []), id]]
            ]).catch(e => {
              // failed to add the device.  Report and ignore.
              reportException(e, 'createPushDevice failed in pushNotifications usePushNotifications useEffect');
            });
          } else if (!thisDevice.updatedAt || parseISO(thisDevice.updatedAt) < subDays(new Date(), 1)) {
            // the token is already registered with the backend and the updatedAt timestamp is older than a day.  Bump
            // the 'updatedAt' so we know it isn't stale.
            patchPushDevice({ userId, token: thisDeviceToken }, { updatedAt: 'now' }).catch(e => {
              // failed to patch the device.  Report and ignore.
              reportException(e, 'patchPushDevice failed in pushNotifications usePushNotifications useEffect');
            });
          }
        }).catch(e => {
          // failed to retrive the push devices.  Report and ignore.
          reportException(e, 'fetchPushDeviceList failed in pushNotifications usePushNotifications useEffect');
        });
      });

      const registrationErrorHandler = PushNotifications.addListener('registrationError', (error: { error: Error }) => {
        reportException(error.error || 'missing error',
          'PushNotifications.addListener(registrationError) failed in pushNotifications usePushNotifications useEffect');
      });

      const pushActionPerformedHandler = PushNotifications.addListener('pushNotificationActionPerformed', (actionPerform: PushNotificationActionPerformed) => {
        actionPerform.notification?.data?.link && history.push(actionPerform.notification.data.link);
      });

      const pushReceivedHandler = PushNotifications.addListener('pushNotificationReceived', () => {
        refetchAlerts({})
          .catch(e => reportException(e, 'refetchAlerts failed in pushNotifications usePushNotifications useEffect pushReceivedHandler'));
      });

      requestAndRegister();

      // return a clean up function
      return () => {
        registrationHandler.remove();
        registrationErrorHandler.remove();
        pushActionPerformedHandler.remove();
        pushReceivedHandler.remove();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, pushEnabled, date]);
}

export function usePushNotificationSignout (): (userId: string) => Promise<void> {
  const deletePushDevice = useFetcher(UserPushDeviceResource.deleteShape());
  return async (userId: string) => {
    if (userId) {
      try {
        await deleteStoredPushDevice({ userId }, deletePushDevice);
      } catch (e) {
        // Unexpected but not critical.  Report and ignore.
        reportException(e, 'deleteStoredPushDevice failed in pushNotifications usePushNotificationSignout');
      }
    }
  };
}

async function deleteStoredPushDevice (
  { userId, tokenToKeep }: { userId: string; tokenToKeep?: string },
  deletePushDevice: (params: Record<string, unknown>, body: undefined) => Promise<unknown>
) {
  const { value } = await Storage.get({ key: CURRENT_TOKEN_STORAGE_KEY });
  if (value && value !== tokenToKeep) {
    await deletePushDevice({ userId: userId, token: value }, undefined).catch(e => {
      // This should never fail.  Report and ignore.
      reportException(e, 'deletePushDevice failed in pushNotifications deleteStoredPushDevice');
    });
  }
}

function requestAndRegister (): void {
  PushNotifications.requestPermission().then(result => {
    if (result.granted) {
      if (isPlatform('android')) {
        // FCM Importance:
        // IMPORTANCE_HIGH = 4: Higher notification importance: shows everywhere, makes noise and peeks. May use full screen intents.
        // IMPORTANCE_LOW = 2: Low notification importance: Shows in the shade, and potentially in the status bar (see shouldHideSilentStatusBarIcons()),
        //   but is not audibly intrusive.
        PushNotifications.createChannel({
          id: constants.ANDROID_PUSH_DEFAULT_CHANNEL_ID,
          name: 'Default',
          importance: 4,
          visibility: 1
        })
          .catch(e => reportException(e, 'PushNotifications.createChannel(default) failed in pushNotifications requestAndRegister'));
        PushNotifications.createChannel({
          id: constants.ANDROID_PUSH_SILENT_CHANNEL_ID,
          name: 'Silent',
          importance: 2,
          visibility: 1
        })
          .catch(e => reportException(e, 'PushNotifications.createChannel(silent) failed in pushNotifications requestAndRegister'));
        // Register with Apple / Google to receive push via APNS/FCM
      }
      PushNotifications.register()
        .catch(e => reportException(e, 'PushNotifications.register failed in pushNotifications requestAndRegister'));
    }
  }).catch(e => {
    // Not sure if this can fail with any error.  Report and ignore.
    reportException(e, 'PushNotifications.requestPermission failed in pushNotifications requestAndRegister');
  });
}

async function calcDeviceName (): Promise<string> {
  if (isPlatform('ios')) {
    const info = await Device.getInfo();
    return info?.name ? info.name : 'IOS Device';
  } else if (isPlatform('android')) {
    return 'Android Device';
  } else {
    return 'Unknown Device';
  }
}

function calcPlatform (): 'APN' | 'FCM' {
  if (isPlatform('ios')) {
    return 'APN';
  } else {
    return 'FCM';
  }
}
