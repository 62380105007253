export function calculateCustomerIds (groupIds: string[], groupToCustomerIds: Map<string, Set<string>>) {
  const customerIds = new Set<string>([]);
  for (const groupId of groupIds) {
    const custIds = groupToCustomerIds.get(groupId)?.values();
    if (custIds) {
      for (const customerId of Array.from(custIds)) {
        customerIds.add(customerId);
      }
    }
  }

  return Array.from(customerIds);
}
