import React from 'react';
import { UserLayoutPage } from '../../containers/UserLayout';
import {
  IonBackButton,
  IonButtons,
  IonItem,
  IonLabel,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import { UserDropdown } from '../../components/Dropdowns';
import { useHistory, useParams } from 'react-router';
import { useFetcher } from 'rest-hooks';
import { GroupResource } from '../../models/group';
import { Form, Formik, FormikHelpers } from 'formik';
import { reportException } from '../../libs/errors';
import { schemas } from 'sprancer-shared';
import { ColorSelectInput, IonFormikInput, ToggleInput, UnexpectedFormErrors } from '../../components/Forms';
import { TextSaveButton } from '../../components/FormButtons';

export default function NewGroup () {
  return (
    <UserLayoutPage
      header={<Header/>}
      content={<Content/>}
    />
  );
}

function Header () {
  const { businessId } = useParams<{ businessId: string }>();

  return (
    <>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton text='Cancel' defaultHref={`/businesses/${businessId}/customers`}/>
        </IonButtons>
        <IonTitle>New Group</IonTitle>
        <UserDropdown slot="end" />
      </IonToolbar>
    </>
  );
}

function Content () {
  const { businessId } = useParams<{ businessId: string }>();
  const history = useHistory();

  const create = useFetcher(GroupResource.createShape());

  async function handleSubmit (values: schemas.GroupCreateType, actions: FormikHelpers<schemas.GroupCreateType>) {
    try {
      await create({ businessId }, values, [
        [GroupResource.listShape(), { businessId }, (id, ids) => [...(ids || []), id]]
      ]);

      actions.setSubmitting(false);
      history.replace(`/businesses/${businessId}/customers`);
    } catch (e) {
      reportException(e, 'handleSubmit failed in NewGroup Content');
      actions.setStatus(e.message || e);
      actions.setSubmitting(false);
    }
  }

  const initialValues: schemas.GroupCreateType = schemas.GroupCreateSchema.required().default();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schemas.GroupCreateSchema}
    >
      {({ isSubmitting, dirty }) => (
        <Form>
          <UnexpectedFormErrors expectedErrors={['name', 'color']}/>
          <IonFormikInput name='name' label={<>Name</>} autocapitalize={'words'} autocorrect={'on'} spellcheck={true} type='text'/>
          <ToggleInput name='priv' onValue={true} offValue={false} label={'Private'}/>
          <ColorSelectInput name='color' label={<>Color</>} />
          <IonItem lines="none" className='text-center'>
            <IonLabel>
              <TextSaveButton disabled={isSubmitting || !dirty} isLoading={isSubmitting} />
            </IonLabel>
          </IonItem>
        </Form>
      )}
    </Formik>
  );
}
