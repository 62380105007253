import React, { useContext } from 'react';
import { BusinessContext, UserLayoutPage } from '../../containers/UserLayout';
import {
  IonBackButton,
  IonButtons,
  IonItem,
  IonTitle,
  IonToolbar, IonLabel
} from '@ionic/react';
import { UserDropdown } from '../../components/Dropdowns';
import { useParams } from 'react-router';
import {
  CopyActivationLinkButtonForGroup,
  ShowActivationLinkQRCodeButtonForGroup
} from '../../components/Activations';
import { MissingSomethingWarning } from '../../components/Warnings';
import { Form, Formik } from 'formik';
import { IonFormikInput } from '../../components/Forms';
import { PrivateGroupIcon, ReferrerCodeIcon } from '../../libs/icons';
import { useResource } from 'rest-hooks';
import { schemas } from 'sprancer-shared';
import { BusinessResource } from '../../models/business';
import * as yup from 'yup';
import { GroupSelect } from '../../components/Groups';
import { useHistory } from 'react-router-dom';
yup.setLocale({
  mixed: {
    required: 'required'
  }
});

export default function NewCustomer () {
  return (
    <UserLayoutPage
      header={<Header/>}
      content={<Content/>}
    />
  );
}

function Header () {
  const { businessId } = useParams<{ businessId: string }>();

  return (
    <>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton defaultHref={`/businesses/${businessId}/customers/`}/>
        </IonButtons>
        <IonTitle>Add Customers</IonTitle>
        <UserDropdown slot="end" />
      </IonToolbar>
    </>
  );
}

const NewCustomerFormSchema = yup.object({
  nickname: yup.string().required().default(''),
  avatar: yup.string().notRequired(),
  email: yup.string().email().required().default(''),
  referrer: yup.string().max(30).matches(/^[a-zA-Y0-9]+$/, 'Must be only numbers or letters (except Z)')
});

type NewCustomerFormType = schemas.TokenUserCreateType & {
  referrer?: string;
  groupId: string;
}

function Content () {
  const { businessId } = useParams<{ businessId: string }>();

  const history = useHistory<{privateGroupId: string}>();
  const privateGroupId = history.location.state?.privateGroupId;

  const business = useResource(BusinessResource.detailShape(), { id: businessId });
  const { privGroupsById } = useContext(BusinessContext);

  if (!business.publicActivationSecretId) {
    return (
      <MissingSomethingWarning
        title={'No public activation link'}
        message={'Please create a public activation link before adding customers.'}
        buttonLink={`/businesses/${businessId}/settings/edit`}
        buttonTitle={'Edit Settings'} />
    );
  }

  const initialValues: NewCustomerFormType = { referrer: '', groupId: privateGroupId || '', ...schemas.TokenUserCreateSchema.required().default() };

  return (<>
    <Formik
      initialValues={initialValues}
      initialTouched={{ referrer: true }}
      onSubmit={() => { /* No-op */ }}
      validationSchema={NewCustomerFormSchema} >
      {({ values, errors }) => {
        const group = privGroupsById.get(values.groupId);
        return (
          <Form>
            <IonItem>
              <div className={'mr-2'}><PrivateGroupIcon /></div>
              <GroupSelect name='groupId' placeholder={'Private Group (optional)'} groupsById={privGroupsById} />
            </IonItem>
            <IonFormikInput name={'referrer'} type={'text'} placeholder={'Referrer Code (optional)'} label={<ReferrerCodeIcon />}/>
            <IonItem lines={'none'}>
              <IonLabel>Share a reusable link</IonLabel>
            </IonItem>
            <IonItem lines="none">
              <IonLabel>
                <ShowActivationLinkQRCodeButtonForGroup business={business} group={group} referrerType='C' referrer={values.referrer} disabled={!!errors.referrer} />
              </IonLabel>
            </IonItem>
            <IonItem lines="none">
              <IonLabel>
                <CopyActivationLinkButtonForGroup business={business} group={group} referrerType='C' referrer={values.referrer} disabled={!!errors.referrer} />
              </IonLabel>
            </IonItem>
          </Form>
        );
      }}
    </Formik>
  </>);
}
