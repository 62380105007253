import { BaseBusinessResource } from './api';
import { schemas } from 'sprancer-shared';

export interface CustomerResource extends schemas.CustomerType {}
export class CustomerResource extends BaseBusinessResource {
  belongsToGroup (groupId: string): boolean {
    return this.groupIds && this.groupIds.indexOf(groupId) >= 0;
  }

  pk () {
    return this.id;
  }

  static customExpiryListShape<T extends typeof BaseBusinessResource> (this: T, { dataExpiryLength }: { dataExpiryLength: number }) {
    return {
      ...this.listShape(),
      options: { ...this.listShape().options, dataExpiryLength }
    };
  }

  static urlRoot = '/customers';
}
