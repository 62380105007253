import {
  Plugins
} from '@capacitor/core';
import { useEffect, useState } from 'react';
import { isPlatform } from '@ionic/react';
import { reportException } from './errors';

const { Storage } = Plugins;

const FONT_SIZE_CLASS_NAME_STORAGE_KEY = 'FontSizeClassName';

export type FontSize = 'large' | '';

export function useFontSize (): [FontSize, (fontSize: FontSize) => Promise<void>] {
  const [fontSize, setFontSize] = useState<'large' | ''>('');

  useEffect(() => {
    if (isPlatform('ios')) {
      Storage.get({ key: FONT_SIZE_CLASS_NAME_STORAGE_KEY }).then(({ value }) => (
        setFontSize((value || '') as 'large' | ''))
      ).catch(e => reportException(e, 'Storage.get failed in fonstSize useFontSize useEffect'));
    }
  }, []);

  async function storeAndSetFontSize (newFontSize: FontSize) {
    if (isPlatform('ios')) {
      setFontSize(newFontSize);
      if (newFontSize) {
        Storage.set({ key: FONT_SIZE_CLASS_NAME_STORAGE_KEY, value: newFontSize }).catch(e => {
          // this shouldn't be possible
          reportException(e, 'Storage.set failed in fonstSize useFontSize storeAndSetFontSize');
        });
      } else {
        Storage.remove({ key: FONT_SIZE_CLASS_NAME_STORAGE_KEY }).catch(e => {
          // this shouldn't be possible
          reportException(e, 'Storage.remove failed in fonstSize useFontSize storeAndSetFontSize');
        });
      }
    }
  }

  return [fontSize, storeAndSetFontSize];
}
