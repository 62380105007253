import { AmplifyResource } from './api';
import { schemas } from 'sprancer-shared';
import { format, isThisYear, parseISO } from 'date-fns';

export interface BusinessResource extends schemas.BusinessType {}
export class BusinessResource extends AmplifyResource {
  constructor (init?: schemas.BusinessCreateType) {
    super();
    Object.assign(this, init);
  }

  fullName () {
    return this.contactName + ' @ ' + this.businessName;
  }

  formattedCreatedAt () {
    const d = parseISO(this.createdAt);
    return isThisYear(d) ? format(d, 'MMMM do') : format(d, 'MMMM do yyyy');
  }

  pk (): string | undefined {
    return this.id;
  }

  static urlRoot = '/businesses';
}
