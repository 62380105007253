import React from 'react';
import { IonAvatar, IonLabel } from '@ionic/react';

export const DEFAULT_AVATAR = 'avatar02';

export const AVATAR_URL_MAP: { [key: string]: string } = {
  avatar01: '/assets/img/avatars/avatar01.svg',
  avatar02: '/assets/img/avatars/avatar02.svg',
  avatar03: '/assets/img/avatars/avatar03.svg',
  avatar04: '/assets/img/avatars/avatar04.svg'
};

const STATUS_BADGE_MAP: { [key: string]: string } = {
  active: 'badge-success',
  deleted: 'badge-danger',
  pending: 'badge-warning',
  rejected: 'badge-danger'
};

export function AvatarStack ({ avatars, size = '' }: { avatars: string[]; size?: 'xs' | 'sm' | '' | 'lg' }) {
  return (<span className='avatars-stack'>
    { avatars.map((avatar, i) => {
      const avatarUrl = AVATAR_URL_MAP[avatar || DEFAULT_AVATAR] || AVATAR_URL_MAP[DEFAULT_AVATAR];
      return (
        <IonAvatar key={i} className={`avatar${size && `-${size}`}`}>
          <img src={avatarUrl} className={'border bg-white'} alt={avatar} />
        </IonAvatar>
      );
    })}
  </span>);
}

export function AvatarImage ({ status, avatar, alt, size, slot, className = '', ...rest }:
  { status?: string; avatar?: string; size: 'xs' | 'sm' | 'md' | 'lg'; slot?: string; alt?: string; className?: string }) {
  const badge = status && STATUS_BADGE_MAP[status];
  const avatarUrl = AVATAR_URL_MAP[avatar || DEFAULT_AVATAR] || AVATAR_URL_MAP[DEFAULT_AVATAR];
  return (
    <IonAvatar className={`avatar-${size}`} { ...slot && { slot } } >
      <img src={avatarUrl} className={`border w-100 bg-white ${className}`} alt={alt || avatar} {...rest} />
      {badge && <span className={`${badge}`}/>}
    </IonAvatar>
  );
}

export function AvatarWithName ({ avatar, name, size = 'md' }: {avatar?: string, name: React.ReactNode, size?: 'xs' | 'sm' | 'md' | 'lg' }) {
  return (
    <>
      <AvatarImage avatar={avatar} size={size}/>
      <IonLabel className={'ml-1 text-muted text-wrap'}>{name}</IonLabel>
    </>
  );
}
