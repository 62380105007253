import { Redirect, Route } from 'react-router';
import React from 'react';
import { Switch } from 'react-router-dom';
import { IonRouterOutletWrapper } from '../../components/RouterOutlet';
import UserRedeemCode from './UserRedeemCode';
import { IonButtons, IonMenuButton, IonTitle, IonToolbar, isPlatform } from '@ionic/react';
import { UserLayoutPage } from '../../containers/UserLayout';
import { UserDropdown } from '../../components/Dropdowns';
import { MissingSomethingWarning } from '../../components/Warnings';

export default function UserAccount () {
  if (isPlatform('hybrid')) {
    return <LookingForAccountPage />;
  }

  // IonRouterOutletWrapper for an explanation of this nonsense.
  const routes = [
    { path: '/user/account/redeemCode', name: 'Redeem Code', component: <UserRedeemCode /> }
  ];

  return (
    <Switch>
      <Route exact path={routes[0].path} name={routes[0].name}><IonRouterOutletWrapper outletRoutes={routes}/></Route>
      <Redirect to={'/user/account/redeemCode'} />
    </Switch>
  );
}

function LookingForAccountPage () {
  return (
    <UserLayoutPage
      header={<IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton autoHide={true}/>
        </IonButtons>
        <IonTitle>Account</IonTitle>
        <UserDropdown slot="end"/>
      </IonToolbar>}
      content={<MissingSomethingWarning
        title={'Looking for your account settings?'}
        message={'Please go to Sprancer on the web to manage your account.'}
       />}
    />
  );
}
