import { schemas } from 'sprancer-shared';
import { AmplifyResource } from './api';
import { Resource } from 'rest-hooks';

export class NotificationUnsubscribeResource extends AmplifyResource {
  pk () {
    return '0';
  }

  static unsubscribeShape<T extends typeof Resource> (this: T) {
    return {
      ...this.createShape(),
      fetch: ({ userId }: { userId: string }, body?: Readonly<schemas.NotificationUnsubscribeType>) => {
        return this.fetch('post', `/notifications/${userId}/unsubscribe`, body);
      }
    };
  }
}
