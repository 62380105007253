import { UserResource } from '../../models/user';
import { useFetcher } from 'rest-hooks';
import { useHistory } from 'react-router';
import { reportException } from '../../libs/errors';
import React, { useEffect, useState } from 'react';
import {
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonHeader,
  IonRow,
  IonSpinner,
  IonText, IonTitle, IonToolbar
} from '@ionic/react';
import { NoAuthLayoutPage } from '../../containers/NoAuthLayout';

export default function UserProfileCreate () {
  return (
    <NoAuthLayoutPage
      header={<Header/>}
      content={
        <IonGrid>
          <IonRow class="ion-align-items-center" style={{ minHeight: '90vh' }}>
            <IonCol size={'12'} size-md={'8'} offset-md={'2'} size-xl={'6'} offset-xl={'3'}>
              <Content />
            </IonCol>
          </IonRow>
        </IonGrid>
      }
    />);
}

function Header () {
  return (
    <IonHeader>
      <IonToolbar>
        <IonTitle>User Profile Create</IonTitle>
      </IonToolbar>
    </IonHeader>
  );
}

function Content () {
  const history = useHistory();

  const createUser = useFetcher(UserResource.createCognitoUserShape());
  const [response, setResponse] = useState({ error: '' });

  async function handleUserCreate () {
    try {
      await createUser({}, { biz: true });
      history.replace('/user/home');
    } catch (e) {
      setResponse({ error: e.message });
      // Failed to create the user.  Not much we can do now, just report the exception;
      reportException(e, 'createUser failed in UserProfileCreate Content handleUserCreate');
    }
  }

  useEffect(() => {
    // We have an authenticated cognito session with no user on the backed.  Create the backend user now.
    handleUserCreate().catch(e =>
      reportException(e, 'handleUserCreate failed in UserProfileCreate Content useEffect')
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IonCard size-md={50}>
      <IonCardContent>
        {
          !response.error
            ? <>
            <h1 className={'text-center'}>Welcome!</h1>
            <p>Creating profile...</p>
            <div className={'text-center'}><IonSpinner color='secondary'/></div>
          </>
            : <>
            <p className='ion-padding-bottom'><IonText color='danger'>{response.error}</IonText></p>
            <p>An error occurred while creating your profile.  Please contact support@sprancer.com for help or <a href='/'>try again</a>.</p>
          </>
        }
      </IonCardContent>
    </IonCard>
  );
}
