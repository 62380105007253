import * as BACKGROUND from '../assets/img/homebggradient.jpg';
import { usePrefersDarkMode } from './mediaQueries';

export function useBackground (): { contentStyle: Record<string, string> } {
  let background = `url('${BACKGROUND.default}') no-repeat`;
  if (usePrefersDarkMode()) {
    // darken the background by overlaying a transparent gardient
    background = 'linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), ' + background;
  }

  // turn off the ionic shadow '--background' varible and use a css variable.  --background was flickering on ios.
  const contentStyle = { '--background': 'none', background: background };
  return ({ contentStyle });
}
