import { useHistory } from 'react-router';
import {
  IonBackButton,
  IonButtons,
  IonItem,
  IonLabel,
  IonList,
  IonTitle,
  IonToolbar,
  IonNote,
  IonItemDivider
} from '@ionic/react';
import { useFetcher, useResource } from 'rest-hooks';
import { schemas } from 'sprancer-shared';
import { Form, Formik, FormikHelpers } from 'formik';
import { reportException } from '../../libs/errors';
import {
  DeleteableItem,
  NotificationFrequencyInput, ToggleInput,
  UnexpectedFormErrors
} from '../../components/Forms';
import { TextSaveButton } from '../../components/FormButtons';
import React from 'react';
import { UserLayoutPage } from '../../containers/UserLayout';
import { useGetCurrentUser, UserResource } from '../../models/user';
import * as yup from 'yup';
import { UserDropdown } from '../../components/Dropdowns';
import { UserPushDeviceResource } from '../../models/userPushDevice';

yup.setLocale({
  mixed: {
    required: 'required'
  }
});

export default function UserNotificationsEdit () {
  return (
    <UserLayoutPage
      header={<Header/>}
      content={<Content/>}
    />
  );
}

function Header () {
  return (
    <>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton text='Cancel' defaultHref={'/user/notifications'}/>
        </IonButtons>
        <IonTitle>Edit Notifications</IonTitle>
        <UserDropdown slot="end"/>
      </IonToolbar>
    </>
  );
}

const UpdateNotificationsSchema = yup.object({
  directMessageNotificationFrequency: yup.string().oneOf(schemas.FREQUENCIES),
  bizCustomerAlertFrequency: yup.string().oneOf(schemas.FREQUENCIES),
  notificationFrequency: yup.string().oneOf(schemas.FREQUENCIES),
  emailEnabled: yup.string().oneOf(['true', 'false']).required(),
  emailAlways: yup.string().oneOf(['true', 'false']).required(),
  pushEnabled: yup.string().oneOf(['true', 'false']).required()
});

type UpdateNotificationsType = {
  directMessageNotificationFrequency?: schemas.FrequencyType;
  bizCustomerAlertFrequency?: schemas.FrequencyType;
  notificationFrequency?: schemas.FrequencyType;
  sharedNotificationFrequency?: string;
  emailEnabled: string;
  emailAlways: string;
  pushEnabled: string;
  deleteDevices: Record<string, boolean>;
}

function map5MinutesToInstant (freq: schemas.FrequencyType): schemas.FrequencyType {
  return freq === '5 minutes' ? 'instant' : freq;
}

function Content () {
  const history = useHistory();
  const user = useGetCurrentUser();

  const pushDevices = useResource(UserPushDeviceResource.listShape(), { userId: user.userId });
  const deletePushDevice = useFetcher(UserPushDeviceResource.deleteShape());

  const patch = useFetcher(UserResource.partialUpdateShape());

  async function handleSubmit (values: UpdateNotificationsType, actions: FormikHelpers<UpdateNotificationsType>) {
    try {
      for (const [token, shouldDelete] of Object.entries(values.deleteDevices)) {
        if (shouldDelete) {
          await deletePushDevice({ userId: user.userId, token }, undefined);
        }
      }

      const patchValues: schemas.UserPatchType = {
        emailEnabled: values.emailEnabled === 'true',
        emailAlways: values.emailAlways === 'true',
        pushEnabled: values.pushEnabled === 'true',
        directMessageNotificationFrequency: values.directMessageNotificationFrequency,
        ...user.hasBusiness() && { bizCustomerAlertFrequency: values.bizCustomerAlertFrequency },
        notificationFrequency: values.notificationFrequency
      };
      await patch({ id: user.userId }, patchValues);
      actions.setSubmitting(false);
      history.goBack();
    } catch (e) {
      reportException(e, 'handleSubmit failed in UserNotificationsEdit Content');
      actions.setStatus(e.message || e);
      actions.setSubmitting(false);
    }
  }

  const initialValues: UpdateNotificationsType = {
    directMessageNotificationFrequency: map5MinutesToInstant(user.directMessageNotificationFrequency || 'instant'),
    bizCustomerAlertFrequency: map5MinutesToInstant(user.bizCustomerAlertFrequency || 'instant'),
    notificationFrequency: map5MinutesToInstant(user.notificationFrequency || schemas.DEFAULT_FREQUENCY),
    emailEnabled: (user.emailEnabled || user.emailEnabled === undefined) ? 'true' : 'false',
    emailAlways: (user.emailAlways) ? 'true' : 'false',
    pushEnabled: (user.pushEnabled || user.pushEnabled === undefined) ? 'true' : 'false',
    deleteDevices: {}
  };

  return (
    <IonList>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={UpdateNotificationsSchema}
      >
        {({ isSubmitting, dirty, values }) => {
          return (
            <Form>
              <UnexpectedFormErrors
                expectedErrors={['directMessageNotificationFrequency', 'notificationFrequency', 'emailEnabled', 'pushEnabled']}/>
              <IonList className={'ion-margin-bottom'}>
                <IonItemDivider>Notification Frequency</IonItemDivider>
                <IonItem lines='none'><IonNote className={'small'}>How often I should be notified of new messages or
                  other activity.</IonNote></IonItem>
                <NotificationFrequencyInput name='directMessageNotificationFrequency' label={'Direct Messages'}/>
                <NotificationFrequencyInput name='bizCustomerAlertFrequency' label={'New Customers'}/>
                <NotificationFrequencyInput name='notificationFrequency' label={'Everything Else'}/>
              </IonList>
              <IonList className={'ion-margin-bottom'}>
                <IonItemDivider>Email Notifications</IonItemDivider>
                <ToggleInput name='emailEnabled' onValue={'true'} offValue={'false'}
                             label={'Send When Push Is Unavailable'}/>
                <ToggleInput name='emailAlways' onValue={'true'} offValue={'false'}
                             label={'Always Send'}/>
              </IonList>
              <IonList className={'ion-margin-bottom'}>
                <IonItemDivider>Push Notifications</IonItemDivider>
                <ToggleInput name='pushEnabled' onValue={'true'} offValue={'false'}
                             label={values.pushEnabled === 'true' ? 'Enabled' : 'Disabled'}/>
                <IonItem lines='none'><IonNote
                  className={'small'}>{pushDevices.length > 0 ? 'Devices:' : 'No Registered Devices'}</IonNote></IonItem>
                {pushDevices.map((d) =>
                  <IonItem key={d.token} lines='none'>
                    <DeleteableItem name={`deleteDevices[${d.token}]`} text={d.nameWithDate()}/>
                  </IonItem>
                )}
              </IonList>
              <IonItem lines="none" className='text-center'>
                <IonLabel>
                  <TextSaveButton disabled={isSubmitting || !dirty} isLoading={isSubmitting} />
                </IonLabel>
              </IonItem>
            </Form>
          );
        }}
      </Formik>
    </IonList>
  );
}
