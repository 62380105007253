import React, { useState } from 'react';
import { useFetcher } from 'rest-hooks';
import { schemas } from 'sprancer-shared';
import { useHistory, useParams } from 'react-router';
import { Form, Formik, FormikHelpers } from 'formik';
import { CancelClickButton, SaveButton } from '../../components/FormButtons';
import { reportException } from '../../libs/errors';
import { UnexpectedFormErrors } from '../../components/Forms';
import { ActivationBanner } from '../../components/Activations';
import { IonCol, IonGrid, IonItem, IonLabel, IonRow } from '@ionic/react';
import { NoAuthLayoutPage } from '../../containers/NoAuthLayout';
import { useBackground } from '../../libs/background';
import { NotificationUnsubscribeResource } from '../../models/notification';
// import { format } from 'date-fns';

export default function Unsubscribe () {
  const { contentStyle } = useBackground();

  return (
    <NoAuthLayoutPage
      header={<Header/>}
      content={<Content/>}
      contentStyle={contentStyle}
    />
  );
}

function Header () {
  return (<></>);
}

function Content () {
  const history = useHistory();
  const { userId, urlEncodedEmail } = useParams<{ userId: string, urlEncodedEmail: string }>();
  const email = decodeURIComponent(urlEncodedEmail);

  const unsubscribe = useFetcher(NotificationUnsubscribeResource.unsubscribeShape());

  const [unsubscribed, setUnsubscribed] = useState(false);

  async function handleSubmit (values: schemas.NotificationUnsubscribeType, actions: FormikHelpers<schemas.NotificationUnsubscribeType>) {
    try {
      await unsubscribe({ userId: userId }, values);
      setUnsubscribed(true);
      actions.setSubmitting(false);
    } catch (e) {
      reportException(e, 'unsubscribe failed in Unsubscribe Content handleSubmit');
      actions.setStatus(e.message || e);
      actions.setSubmitting(false);
    }
  }

  const initialValues: schemas.NotificationUnsubscribeType = schemas.NotificationUnsubscribeSchema.required().default();
  initialValues.email = email;

  return (
    <div className={'mb-4'}>
      <ActivationBanner />
      <IonGrid className='py-3 px-0'>
        <IonRow className='p-0'>
          <IonCol className='p-0' size={'12'} size-md={'8'} offset-md={'2'} size-xl={'6'} offset-xl={'3'}>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={schemas.NotificationUnsubscribeSchema}
            >
              {({ isSubmitting }) => (
                <Form>
                  <UnexpectedFormErrors expectedErrors={['nickname', 'email']}/>
                  <IonItem lines='none'>
                    <h4 className={'text-muted text-breakmb 3'}>
                      {unsubscribed
                        ? <>Email notifications have been turned off for <strong>{email}</strong></>
                        : <>Would you like to turn off email notifications for <strong>{email}</strong>?</>
                      }
                    </h4>
                  </IonItem>
                  <IonItem lines={'none'}>
                    <IonLabel>
                      <SaveButton expand={'block'} size='default' text={<>Unsubscribe</>} disabled={isSubmitting || unsubscribed} loadingText={'Unsubscribing…'} isLoading={isSubmitting} />
                    </IonLabel>
                  </IonItem>
                  <IonItem lines={'none'} >
                    <IonLabel className={'pb-3'}>
                      <CancelClickButton expand={'block'} text={ unsubscribed ? 'Done' : 'Cancel' } size='default' color={'secondary'} onClick={() => { history.replace('/'); }} />
                    </IonLabel>
                  </IonItem>
                </Form>
              )}
            </Formik>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
}
