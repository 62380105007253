import React, { ReactNode, useState } from 'react';
import { IonPopover, IonList, IonItem, IonItemDivider, isPlatform, IonButton, IonLabel } from '@ionic/react';
import { AvatarImage } from './Avatars';
import { useGetCurrentUser } from '../models/user';
import { useAppContext } from '../libs/context';
import { useHistory } from 'react-router';

export function Dropdown ({ cssClass, buttonContent, children, ...props } :
  { cssClass?: string, buttonContent: ReactNode, children: ReactNode } & Parameters<typeof IonButton>[0]) {
  const [showPopover, setShowPopover] = useState<{ open: boolean, event: Event | undefined }>({
    open: false,
    event: undefined
  });

  function close () {
    setShowPopover({ open: false, event: undefined });
  }

  return (
    <>
      <IonPopover
        cssClass={cssClass}
        isOpen={showPopover.open}
        event={showPopover.event}
        onDidDismiss={close} >
        <IonList>
          {children}
        </IonList>
      </IonPopover>
      <IonButton {...props} onClick={(e) => setShowPopover({ open: true, event: e.nativeEvent }) }>{buttonContent}</IonButton>
    </>
  );
}

export function UserDropdown ({ slot } : {slot: string, showUserLinks?: boolean}) {
  const { signOut } = useAppContext();
  const history = useHistory();

  const user = useGetCurrentUser();

  const [showPopover, setShowPopover] = useState<{ open: boolean, event: Event | undefined }>({
    open: false,
    event: undefined
  });

  function close () {
    setShowPopover({ open: false, event: undefined });
  }

  const popoverItems = [];
  popoverItems.push(<IonItem key={'user'} lines="none"><IonLabel><h2>Logged in as:</h2><p>{user.nickname}</p></IonLabel></IonItem>);
  popoverItems.push(<IonItemDivider key={'divider'}/>);
  popoverItems.push(<IonItem key={'profile'} lines="none" button routerLink={'/user/profile'} detail>Profile</IonItem>);
  if (!isPlatform('hybrid')) { // only show the logout button on the web app.
    popoverItems.push(<IonItem key={'logout'} button detail={false} lines="none" onClick={() => signOut && signOut(history, user.userId)}>Logout</IonItem>);
  }

  return (
    <>
      <IonPopover
        isOpen={showPopover.open}
        event={showPopover.event}
        onDidDismiss={close} >
        <IonList>
          {popoverItems}
        </IonList>
      </IonPopover>
      <IonItem
        lines="none"
        detail={false}
        slot={slot}
        onClick={(e) => setShowPopover({ open: true, event: e.nativeEvent }) }
      >
        <AvatarImage avatar={user.avatar} size={'md'}/>
      </IonItem>
    </>
  );
}
