import { UserLayoutPage } from '../../containers/UserLayout';
import {
  IonButton,
  IonButtons,
  IonItem, IonLabel,
  IonMenuButton, IonRow,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import { UserDropdown } from '../../components/Dropdowns';
import { useParams } from 'react-router';
import { AvatarWithName } from '../../components/Avatars';
import React from 'react';
import { BusinessResource } from '../../models/business';
import { useResource } from 'rest-hooks';
import { Logo } from '../../components/Logos';
import { generatePublicActivationLink } from '../../components/Activations';
import { MessageContent, MessageLinkify } from '../../components/Messages';

export default function SettingsOverview () {
  return (
    <UserLayoutPage
      header={<Header/>}
      content={<Content />}
    />
  );
}

function Header () {
  return (
    <IonToolbar>
      <IonButtons slot="start">
        <IonMenuButton autoHide={true}/>
      </IonButtons>
      <IonTitle>Business</IonTitle>
      <UserDropdown slot="end"/>
    </IonToolbar>
  );
}

function Content () {
  const { businessId } = useParams<{ businessId: string }>();
  const business = useResource(BusinessResource.detailShape(), { id: businessId });

  return (<>
    <IonRow>
      <IonButton
        className='ml-auto'
        color="primary"
        fill="clear"
        routerLink={`/businesses/${businessId}/settings/edit`}
      >Edit</IonButton>
    </IonRow>
    <IonRow className='ion-justify-content-center'>
      <AvatarWithName avatar={business.avatar} name={business.contactName + ' @ ' + business.businessName} size={'md'}/>
    </IonRow>
    <IonRow>
      <IonItem className='w-100' lines='none'>
        <IonLabel className={'text-wrap'}>
          <h2>Logo</h2>
          {business.logoImageUrl
            ? <div className={'d-flex flex-column align-items-center'}>
              <div className='my-2 p-2 bg-white rounded d-flex flex-column'>
                <Logo url={business.logoImageUrl} size={'lg'}/>
                <div className='text-dark text-center'>(normal mode)</div>
              </div>
              <div className='my-2 p-2 bg-black rounded d-flex flex-column'>
                <Logo url={business.logoImageUrl} size={'lg'}/>
                <div className='text-light text-center'>(dark mode)</div>
              </div>
            </div>
            : <span className={'text-muted'}>No Logo</span>
          }
        </IonLabel>
      </IonItem>
    </IonRow>
    <IonRow>
      <IonItem lines='none'>
        <IonLabel className={'text-wrap'}>
          <h2>New Customer Link</h2>
          <p>{generatePublicActivationLink(business)}</p>
        </IonLabel>
      </IonItem>
    </IonRow>
    <IonRow>
      <IonItem lines='none'>
        <IonLabel className={'text-wrap'}>
          <h2>Welcome Message</h2>
          <p>{business.welcomeMessage}</p>
        </IonLabel>
      </IonItem>
    </IonRow>
    <IonRow>
      <IonItem lines='none'>
        <IonLabel className={'text-wrap'}>
          <h2>Website URL</h2>
          <p><MessageLinkify>{business.websiteUrl || 'None'}</MessageLinkify></p>
        </IonLabel>
      </IonItem>
    </IonRow>
    <IonRow>
      <IonItem lines='none'>
        <IonLabel className={'text-wrap'}>
          <h2>Hours</h2>
          <p>{business.hours ? <MessageContent message={ { text: business.hours } }/> : 'None'}</p>
        </IonLabel>
      </IonItem>
    </IonRow>
  </>);
}
