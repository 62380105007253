import { UserLayoutPage } from '../../containers/UserLayout';
import {
  IonButton,
  IonButtons,
  IonItem,
  IonMenuButton,
  IonTitle,
  IonToolbar,
  IonList,
  IonLabel,
  IonCol,
  IonRow,
  IonBadge
} from '@ionic/react';
import { UserDropdown } from '../../components/Dropdowns';
import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useResource } from 'rest-hooks';
import { BusinessResource } from '../../models/business';
import {
  CustomersDashboardIcon, DirectMessagesDashboardIcon, PostsDashboardIcon
} from '../../libs/icons';
import { LogoWithName } from '../../components/Logos';
import { AvatarWithName } from '../../components/Avatars';
import { AlertsContext } from '../../components/Alerts';
import { IconType } from 'react-icons';

export default function BusinessDashboard () {
  return (
    <UserLayoutPage
      header={<Header/>}
      content={<Content/>}
    />
  );
}

function Header () {
  return (
    <IonToolbar>
      <IonButtons slot="start">
        <IonMenuButton autoHide={true}/>
      </IonButtons>
      <IonTitle>Business Dashboard</IonTitle>
      <UserDropdown slot="end"/>
    </IonToolbar>
  );
}

function Content () {
  const { businessId } = useParams<{ businessId: string }>();
  const business = useResource(BusinessResource.detailShape(), { id: businessId });

  return (<>
    <IonRow className='ion-justify-content-center'>
      <IonItem className={'p-2'} lines={'none'}>
        { business.logoImageUrl
          ? <LogoWithName url={business.logoImageUrl} name={business.fullName()} size={'md'}/>
          : <AvatarWithName avatar={business.avatar} name={business.fullName()}/> }
      </IonItem>
    </IonRow>

    <IonList>

      <IonItem className='pb-2' lines={'full'}>
        <IonLabel>
          <p className={'text-wrap'}>
            Sprancer is for messaging.  Get started by adding customers and sending out messages.
          </p>
        </IonLabel>
      </IonItem>
      <DashboardItem
        icon={PostsDashboardIcon}
        title={'Post to groups of customers.'}
        button={<IonButton expand='block' size='large' routerLink={`/businesses/${businessId}/posts`}>Posts</IonButton>}
        linkPath={`/businesses/${businessId}/posts`}
      />
      <DashboardItem
        icon={DirectMessagesDashboardIcon}
        title={'Chat with individuals.'}
        button={<IonButton expand='block' size='large' routerLink={`/businesses/${businessId}/messages`}>Direct Messages</IonButton>}
        linkPath={`/businesses/${businessId}/messages`}
      />
      <DashboardItem
        icon={CustomersDashboardIcon}
        title={'Invite more customers.'}
        button={<IonButton expand='block' size='large' routerLink={`/businesses/${businessId}/customers`}>Customers</IonButton>}
        linkPath={`/businesses/${businessId}/customers`}
      />
    </IonList>
  </>);
}

function DashboardItem ({ icon: ListIcon, title, button, linkPath } : { icon: IconType, title: string, button: JSX.Element, linkPath: string }) {
  const { pathToAlertIds } = useContext(AlertsContext);
  const alertCount = pathToAlertIds.get(linkPath)?.length;
  return (
    <IonItem className='py-2' lines={'full'}>
      <Link to={linkPath} slot="start">
        <ListIcon size='3em' color={'gray'} />
        {!!(alertCount && alertCount > 0) &&
          <div className={'mt-n4 d-flex w-100'}>
            <IonBadge className='ml-auto' color="danger">{alertCount}</IonBadge>
          </div>
        }
      </Link>
      <IonRow className={'w-100'}>
        <IonCol size={'12'} className={'py-2'}><IonLabel><p className={'text-wrap'}>{title}</p></IonLabel></IonCol>
        <IonCol size={'12'} className={'py-2'}>
          {button}
        </IonCol>
      </IonRow>
    </IonItem>
  );
}
