import { Auth } from 'aws-amplify';

//
// Password Helpers
//

// These characters match the cognito docs: https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`])[\w^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`]{8,99}$/;
export const ILLEGAL_REGEX = /[^\w^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`]/;

export function passwordMessage ({ value = '' }) {
  const illegal = value.match(ILLEGAL_REGEX);
  if (illegal) {
    return `'${illegal}' is not an allowed character`;
  } else {
    return 'Must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character';
  }
}

//
// AuthErrors
//

export class DomainError extends Error {
  cause: Error;

  constructor (message: string, { cause }: { cause: Error}) {
    super(message);
    // Ensure the name of this error is the same as the class name
    this.name = this.constructor.name;
    this.cause = cause;
  }
}

export class AuthError extends DomainError {
  constructor (message: string, { cause }: { cause: Error}) {
    super(message, { cause: cause });
    // Ensure the name of this error is the same as the class name
    this.name = this.constructor.name;
  }
}

export class CodeMismatchAuthError extends AuthError {
  constructor (e: Error) {
    super(e.message, { cause: e });
    this.name = this.constructor.name;
  }

  static matches (e: { code?: string }) {
    return e.code === 'CodeMismatchException';
  }
}

export class IncorrectPasswordAuthError extends AuthError {
  constructor (e: Error) {
    super(e.message, { cause: e });
    this.name = this.constructor.name;
  }

  static matches (e: { code?: string, message?: string}) {
    return e.code === 'NotAuthorizedException' && e.message === 'Incorrect username or password.';
  }
}

export class UserDisabledAuthError extends AuthError {
  constructor (e: Error) {
    super(e.message, { cause: e });
    this.name = this.constructor.name;
  }

  static matches (e: { code?: string, message?: string}) {
    return e.code === 'NotAuthorizedException' && e.message === 'User is disabled.';
  }
}

export class UsernameExistsAuthError extends AuthError {
  constructor (e: Error) {
    super(e.message, { cause: e });
    this.name = this.constructor.name;
  }

  static matches (e: { code?: string }) {
    return e.code === 'UsernameExistsException';
  }
}

export class UserNotConfirmedAuthError extends AuthError {
  constructor (e: Error) {
    super(e.message, { cause: e });
    this.name = this.constructor.name;
  }

  static matches (e: { code?: string, message?: string }) {
    return (e.code === 'UserNotConfirmedException' ||
      (e.code === 'InvalidParameterException' &&
        e.message === 'Cannot reset password for the user as there is no registered/verified email or phone_number'));
  }
}

export class UserNotFoundAuthError extends AuthError {
  constructor (e: Error) {
    super(e.message, { cause: e });
    this.name = this.constructor.name;
  }

  static matches (e: { code?: string }) {
    return e.code === 'UserNotFoundException';
  }
}

//
// Auth methods
//

export async function currentSession () {
  try {
    return await Auth.currentSession();
  } catch (e) {
    // clean up some of the amplify weirdness: return null if there is no session instead of throwing a string error.
    if (e === 'No current user') {
      return null;
    }

    if (e instanceof Error) { throw e; }
    throw new AuthError(e.message || e, e);
  }
}

export async function forgotPassword (email: string) {
  try {
    return await Auth.forgotPassword(email);
  } catch (e) {
    if (UserDisabledAuthError.matches(e)) { throw new UserDisabledAuthError(e); }
    if (UserNotConfirmedAuthError.matches(e)) { throw new UserNotConfirmedAuthError(e); }
    if (UserNotFoundAuthError.matches(e)) { throw new UserNotFoundAuthError(e); }

    if (e instanceof Error) { throw e; }
    throw new AuthError(e.message || e, e);
  }
}

export async function forgotPasswordSubmit (email: string, confirmationCode: string, newPassword: string) {
  try {
    return await Auth.forgotPasswordSubmit(email, confirmationCode, newPassword);
  } catch (e) {
    if (CodeMismatchAuthError.matches(e)) { throw new CodeMismatchAuthError(e); }
    if (UserDisabledAuthError.matches(e)) { throw new UserDisabledAuthError(e); }
    if (UserNotConfirmedAuthError.matches(e)) { throw new UserNotConfirmedAuthError(e); }
    if (UserNotFoundAuthError.matches(e)) { throw new UserNotFoundAuthError(e); }

    if (e instanceof Error) { throw e; }
    throw new AuthError(e.message || e, e);
  }
}

export async function signOut () {
  try {
    return await Auth.signOut();
  } catch (e) {
    if (e instanceof Error) { throw e; }
    throw new AuthError(e.message || e, e);
  }
}

export async function signIn (email: string, password: string) {
  try {
    return await Auth.signIn(email, password);
  } catch (e) {
    if (IncorrectPasswordAuthError.matches(e)) { throw new IncorrectPasswordAuthError(e); }
    if (UserDisabledAuthError.matches(e)) { throw new UserDisabledAuthError(e); }
    if (UserNotFoundAuthError.matches(e)) { throw new UserNotFoundAuthError(e); }
    if (UserNotConfirmedAuthError.matches(e)) { throw new UserNotConfirmedAuthError(e); }

    if (e instanceof Error) { throw e; }
    throw new AuthError(e.message || e, e);
  }
}

export async function signUp (email: string, password: string) {
  try {
    return await Auth.signUp({ username: email, password: password });
  } catch (e) {
    if (UsernameExistsAuthError.matches(e)) { throw new UsernameExistsAuthError(e); }

    if (e instanceof Error) { throw e; }
    throw new AuthError(e.message || e, e);
  }
}

export async function confirmSignUp (email: string, confirmationCode: string) {
  try {
    return await Auth.confirmSignUp(email, confirmationCode);
  } catch (e) {
    if (e instanceof Error) { throw e; }
    throw new AuthError(e.message || e, e);
  }
}

export async function resendSignUp (email: string) {
  try {
    return await Auth.resendSignUp(email);
  } catch (e) {
    if (CodeMismatchAuthError.matches(e)) { throw new CodeMismatchAuthError(e); }

    if (e instanceof Error) { throw e; }
    throw new AuthError(e.message || e, e);
  }
}
