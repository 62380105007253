import { Route, useParams } from 'react-router';
import React from 'react';
import CustomerList from './CustomerList';
import CustomerDetails from './CustomerDetails';
import CustomerDetailsEdit from './CustomerDetailsEdit';
import NewCustomer from './NewCustomer';
import { Redirect, Switch } from 'react-router-dom';
import { IonRouterOutletWrapper } from '../../components/RouterOutlet';
import { BusinessResourceRefresher } from '../../libs/resourceRefresher';
import NewCustomerQR from './NewCustomerQR';
export default function Customers () {
  const { businessId } = useParams<{ businessId: string }>();

  // IonRouterOutletWrapper for an explanation of this nonsense.
  const routes = [
    {
      path: '/businesses/:businessId/customers/new/new',
      name: 'New Customer',
      component: <BusinessResourceRefresher businessId={businessId}><NewCustomer /></BusinessResourceRefresher>
    },
    {
      path: '/businesses/:businessId/customers/new/qr',
      name: 'New Customer',
      component: <BusinessResourceRefresher businessId={businessId}><NewCustomerQR /></BusinessResourceRefresher>
    },
    {
      path: '/businesses/:businessId/customers/:customerId',
      name: 'Customer Details',
      component: <BusinessResourceRefresher businessId={businessId}><CustomerDetails /></BusinessResourceRefresher>
    },
    {
      path: '/businesses/:businessId/customers/:customerId/editDetails',
      name: 'Customer Details Edit',
      component: <BusinessResourceRefresher businessId={businessId}><CustomerDetailsEdit /></BusinessResourceRefresher>
    },
    {
      path: '/businesses/:businessId/customers/',
      name: 'Customers',
      component: <BusinessResourceRefresher businessId={businessId}><CustomerList /></BusinessResourceRefresher>
    }
  ];

  return (
    <Switch>
      <Route exact path={routes[0].path} name={routes[0].name}><IonRouterOutletWrapper outletRoutes={routes}/></Route>
      <Route exact path={routes[1].path} name={routes[1].name}><IonRouterOutletWrapper outletRoutes={routes}/></Route>
      <Route exact path={routes[2].path} name={routes[2].name}><IonRouterOutletWrapper outletRoutes={routes}/></Route>
      <Route exact path={routes[3].path} name={routes[3].name}><IonRouterOutletWrapper outletRoutes={routes}/></Route>
      <Route exact path={routes[4].path} name={routes[4].name}><IonRouterOutletWrapper outletRoutes={routes}/></Route>
      <Redirect to={'/businesses/:businessId/customers'} />
    </Switch>
  );
}
