import React, { ReactNode, useRef, useState } from 'react';
import Linkify from 'linkifyjs/react';
import { IonCol, IonModal, IonRow, IonSlide, IonSlides, isPlatform } from '@ionic/react';

export function MessageContentPreview ({ message, showImage }: {message: { imageUrl?: string, text: string }, showImage: boolean}) {
  return (
    <>
      <IonRow>
        { showImage && message.imageUrl && <IonCol size={'3'} className={'pr-2'}><img alt='' src={message.imageUrl} className={'img-fluid ion-no-padding'} /></IonCol> }
        <IonCol size={ showImage && message.imageUrl ? '9' : '12' } className='ion-no-padding'> <div className='text-preline text-break line-clamp-3 mb-1'>{message.text}</div></IonCol>
      </IonRow>
    </>
  );
}

export function MessageContent ({ message }: {message: { imageUrl?: string, text: string }}) {
  const [showImageModal, setShowImageModal] = useState(false);
  return (
    <div className='text-preline text-break'>
      { message.imageUrl && <>
        <ImageModal url={message.imageUrl} show={showImageModal} close={ () => setShowImageModal(false)} />
        <div className='text-center'>
          <img alt=''
               onClick={(e) => {
                 if (isPlatform('hybrid')) {
                   e.stopPropagation();
                   setShowImageModal(true);
                 }
               }}
               src={message.imageUrl}
               className={'mw-100 my-3'}/>
        </div>
      </>}
      <MessageLinkify>{message.text}</MessageLinkify>
    </div>
  );
}

export function MessageLinkify ({ children, className = '' }: { children: ReactNode, className?: string }) {
  return (<Linkify options={{
    className: className + ' text-underline',
    attributes: { rel: 'noopener noreferrer nofollow' },
    format: (value, type) => {
      // shorten and elipsis long urls
      if (type === 'url' && value.length > 50) {
        value = value.slice(0, 50) + '…';
      }
      return value;
    }
  }}>{children}</Linkify>);
}

function ImageModal ({ url, show, close }: { url: string, show: boolean; close: () => void }) {
  const sliderRef = useRef<HTMLIonSlidesElement>(null);

  return (
    <IonModal
      isOpen={show}
      onDidDismiss={close}
      cssClass='activation' >
        <IonSlides ref={sliderRef}
                   className="my-4 mx-0 h-100"
                   options={{
                     on: {
                       zoomChange: (scale: number) => {
                         if (scale < 0.6) {
                           close();
                         }
                       }
                     }
                   }}
                   onClick={(e) => {
                     e.stopPropagation();
                     sliderRef.current?.getSwiper().then(swiper => swiper.zoom.out()).catch(() => { /* ignore */ });
                     close();
                   }}>
          <IonSlide className="w-100">
            <div className="swiper-zoom-container">
              <img src={url} alt={'Message image'}/>
            </div>
          </IonSlide>
        </IonSlides>
    </IonModal>
  );
}
