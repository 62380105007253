import { CopyIcon, QRCodeIcon, ShareIcon } from '../libs/icons';
import copy from 'copy-to-clipboard';
import React, { ReactNode, useState } from 'react';
import { GroupResource } from '../models/group';
import QRCode from 'qrcode.react';
import LOGO from '../assets/img/brand/sygnetInRoundedRect.svg';
import { IonButton, IonItem, IonLabel, IonModal, IonRow, isPlatform } from '@ionic/react';
import { Logo } from './Logos';
import { AvatarWithName } from './Avatars';
import * as BACKGROUND from '../assets/img/activationbg.jpg';
import { BusinessResource } from '../models/business';
import { format } from 'date-fns';
import { BusinessProfileType } from 'sprancer-shared/dist/schemas';
import { Plugins } from '@capacitor/core';
import { reportException } from '../libs/errors';
import { CustomerResource } from '../models/customer';
const { Share } = Plugins;

const DELIMETER = 'Z';
export type ReferrerType =
  'C' | // custom
  'U'; // userid

export function getReferrerInfo (referrer: string, customersById: Map<string, CustomerResource>): string {
  if (referrer.startsWith('U')) {
    const customer = customersById.get(referrer.slice(1));
    if (customer) {
      return `Referred by ${customer.nickname}.`;
    }
  } else if (referrer.startsWith('C')) {
    return `Referral code ${referrer.slice(1)}.`;
  }

  return '';
}

export function activationSecretIdWithReferrer (activationSecretId: string, referrerType?: ReferrerType, referrer?: string) {
  if (referrer) {
    return activationSecretId + DELIMETER + referrerType + referrer;
  } else {
    return activationSecretId;
  }
}

export function generateActivationLink (group: GroupResource, referrerType?: ReferrerType, referrer?: string) {
  return group.activationSecretId && generateLinkWithLocation(`/activations/${activationSecretIdWithReferrer(group.activationSecretId, referrerType, referrer)}`);
}

export function generatePublicActivationLink (business: BusinessResource | BusinessProfileType, referrerType?: ReferrerType, referrer?: string) {
  return business.publicActivationSecretId && generateLinkWithLocation(`/activations/${activationSecretIdWithReferrer(business.publicActivationSecretId, referrerType, referrer)}`);
}

export function generateLinkWithLocation (path: string) {
  if (!path) {
    return '';
  }

  let location;
  if (process.env.REACT_APP_STAGE === 'dev' && !isPlatform('hybrid')) {
    location = window.location.origin;
  } else {
    location = 'https://app.sprancer.com';
  }

  return (`${location}${path}`);
}

function ActivationQRModal ({ business, dlname, link, show, close }: { business: BusinessResource | BusinessProfileType, dlname: string, link: string, show: boolean; close: () => void }) {
  return (
    <IonModal
      isOpen={show}
      onDidDismiss={close}
      cssClass='activation' >
      <div className={'p-3 d-flex flex-column justify-space-between h-100'}>
        <h4 className={'ion-text-center'}>Scan with your camera to connect with</h4>
        {business.logoImageUrl && <IonRow className='ion-justify-content-center'>
          <IonItem lines='none'>
            <Logo url={business.logoImageUrl} size={'lg'}/>
          </IonItem>
        </IonRow>}
        <IonRow className='ion-justify-content-center'>
          <IonItem color='transparent' lines='none'>
            <AvatarWithName avatar={business.avatar} name={`${business.contactName} @ ${business.businessName}`}/>
          </IonItem>
        </IonRow>
        <QRContent dlname={dlname} link={link} close={close} />
      </div>
    </IonModal>
  );
}

export function QRContent ({ dlname, link, close }: {dlname: string, link: string, close?: () => void }) {
  return (
    <>
      <p className={'m-auto mb-1'}>
        <a href={link} >
          <QRCode id={dlname}
                  imageSettings={{ src: LOGO, excavate: false, height: 41, width: 41 }}
                  renderAs={'canvas'}
                  size={258}
                  value={link}/>
        </a>
      </p>

      { close && <IonButton size='default' color="primary" onClick={close}>Close</IonButton> }
      { !isPlatform('hybrid') &&
      <IonButton size='default' color="secondary" onClick={() => {
        const canvas = document.getElementById(dlname) as HTMLCanvasElement;
        const pngUrl = canvas?.toDataURL('image/png')?.replace('image/png', 'image/octet-stream');
        if (pngUrl) {
          const downloadLink = document.createElement('a');
          downloadLink.href = pngUrl;
          downloadLink.download = dlname + '.png';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      }}>Download</IonButton>
      }
    </>
  );
}

export function generateDownloadFileName (bizName: string, groupName: string, referrer: string) {
  return `${format(new Date(), 'yyyy-MM-dd')}-${bizName}${groupName ? '-' + groupName : ''}${referrer ? '-' + referrer : ''}`.replace(/[^A-Za-z0-9-]/, '');
}

export function ShowActivationLinkQRCodeButtonForGroup ({ business, group, referrerType, referrer, disabled }:
  { business: BusinessResource | BusinessProfileType, group?: GroupResource, referrerType: ReferrerType, referrer?: string, disabled?: boolean }) {
  const dlname = generateDownloadFileName(business.businessName, group ? group.name : '', referrer || '');
  const link = group ? generateActivationLink(group, referrerType, referrer) : generatePublicActivationLink(business, referrerType, referrer);
  return (
    <ShowActivationLinkQRCodeButton business={business} dlname={dlname} link={link || ''} expand={'block'} disabled={disabled}>
      <div slot={'start'}><QRCodeIcon size='2em'/></div><div className='ml-2'>Show QR</div>
    </ShowActivationLinkQRCodeButton>
  );
}

export function ShowActivationLinkQRCodeButton ({ business, dlname, link, expand, disabled, children }:
  { business: BusinessResource | BusinessProfileType, dlname: string, link: string, expand?: 'full' | 'block', disabled?: boolean, children: ReactNode}) {
  const [showQrModal, setShowQrModal] = useState(false);

  return (
    <>
      <ActivationQRModal
        business={business}
        dlname={dlname}
        link={link}
        show={showQrModal}
        close={() => setShowQrModal(false)}/>
      <IonButton size='default' expand={expand} color={'primary'} disabled={disabled || !link} onClick={() => setShowQrModal(true)} >
        {children}
      </IonButton>
    </>
  );
}

export function CopyActivationLinkButtonForGroup ({ business, group, referrerType, referrer, disabled }:
  { business: BusinessResource | BusinessProfileType, group?: GroupResource, referrerType: ReferrerType, referrer?: string, disabled?: boolean }) {
  const link = group ? generateActivationLink(group, referrerType, referrer) : generatePublicActivationLink(business, referrerType, referrer);
  if (!isPlatform('hybrid')) {
    return (
      <IonButton size='default' expand={'block'} color={'primary'} disabled={disabled || !link}
                 onClick={() => {
                   copy(link || '');
                   alert('Link copied to clipboard');
                 }}>
        <div slot={'start'}><CopyIcon size='2em'/></div><div className='ml-2'>Copy Link</div>
      </IonButton>
    );
  } else {
    return (
      <IonButton size='default' expand={'block'} color={'primary'} disabled={disabled || !link}
                 onClick={() => {
                   Share.share({
                     title: `Connect with ${business.contactName} @ ${business.businessName}`,
                     text: `You\`re  invited to connect with ${business.contactName} @ ${business.businessName} on Sprancer`,
                     url: link,
                     dialogTitle: 'Share Link'
                   }).catch(e => {
                     reportException(e, 'Share.share failed in Activations CopyActivationLinkButton onClick');
                     copy(link || '');
                     alert('Link copied to clipboard');
                   });
                 }}>
        <div slot={'start'}><ShareIcon size='2em'/></div><div className='ml-2'>Share Link</div>
      </IonButton>
    );
  }
}

export function ActivationBanner () {
  return (
    <>
      <div className={'ion-padding bg-blue text-light d-none d-md-block'}
           style={{ backgroundImage: `url('${BACKGROUND.default}')`, backgroundSize: 'cover' }}>
        <h1>Use social media to find new customers.</h1>
        <h1>Use Sprancer to keep them coming back.</h1>
        <p>Sprancer is messaging for <strong>local businesses</strong> and their customers.</p>
      </div>
      <div className={'pt-5 d-md-none'}>
        <IonItem color='transparent' lines='none'>
          <IonLabel>
            <h1 className={'text-wrap text-muted'}>
              Sprancer is messaging for <strong>local businesses</strong> and their customers.
            </h1>
          </IonLabel>
        </IonItem>
      </div>
    </>
  );
}
