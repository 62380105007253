import {
  IonInput,
  IonItem,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonText,
  IonTextarea,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonToggle,
  IonNote,
  IonSelect,
  IonSelectOption, IonCheckbox
} from '@ionic/react';
import { FormikErrors, FormikTouched, FormikValues, useField, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { DeleteIcon, ImageIcon } from '../libs/icons';
import { AVATAR_URL_MAP, AvatarImage } from '../components/Avatars';

export function AvatarRadioInput ({ name, label, stackedLabel, helpText }: { name: string, label?: string, stackedLabel?: string, helpText?: string }) {
  const [{ onChange, ...field }] = useField(name);

  return (<>
    { (label || stackedLabel || helpText) && <IonItem lines='none'>
      {label && <IonLabel className="text-wrap small">{label}</IonLabel>}
      {stackedLabel && <IonLabel className="text-wrap" position="stacked">{stackedLabel}</IonLabel>}
      {helpText && <IonNote className="text-wrap small">{helpText}</IonNote>}
    </IonItem> }
    <IonRadioGroup onIonChange={onChange} {...field}>
      <IonGrid className="p-0 m-0">
        <IonRow className="p-0 m-0">
          {Object.keys(AVATAR_URL_MAP).map((avatar) => (
            <IonCol key={avatar}>
              <IonItem lines='none'>
                <AvatarImage avatar={avatar} size={'md'}/>
                <IonRadio slot="start" value={avatar}/>
              </IonItem>
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
    </IonRadioGroup>
  </>);
}

export function DeleteableMaskedEmailInput ({ name, helpText, updateEmailName, placeholder, label }:
  { name: string, helpText?: string, updateEmailName: string, placeholder?: string, label: string }) {
  const [{ onChange, ...field }, meta] = useField(name);
  const [updateEmailField] = useField({ name: updateEmailName, type: 'checkbox' });
  const { setFieldValue } = useFormikContext();

  return (<>
    <IonItem lines='none'>
      <IonLabel className="text-wrap">
        <h2>{label}</h2>
        {helpText && <p>{helpText}</p>}
      </IonLabel>
    </IonItem>
    <IonItem lines='none'>
      <IonInput
        className={'border px-2'}
        onIonChange={onChange}
        {...field}
        type={'email'}
        disabled={!updateEmailField.checked}
        placeholder={!updateEmailField.checked ? placeholder : 'Email'}/>
      {!updateEmailField.checked &&
      <IonButton slot="end" fill="clear" onClick={() => setFieldValue(updateEmailName, true)}><DeleteIcon size='1.5em'/></IonButton>
      }
    </IonItem>
    {!!(meta.error && meta.touched) &&
    <IonText color="danger" className="ion-padding-start">
      <small>{meta.error}</small>
    </IonText>
    }
  </>);
}

export function DeleteableItem ({ name, text }: {name: string, text: string}) {
  const [field,, { setValue }] = useField({ name: name });

  return (<>

      <IonItem lines='none'>
        <IonLabel className={'text-wrap'}>{ field.value === true ? <del>{text}</del> : text }</IonLabel>
      </IonItem>
      <IonButton
        slot="end"
        fill={field.value ? 'clear' : 'solid' }
        color="danger"
        onClick={() => { setValue(!field.value); }}
      ><DeleteIcon size='1.5em'/></IonButton>
  </>);
}

export function NotificationFrequencyInput ({ name, helpText, label }: { name: string, helpText?: string, label: string }) {
  const [{ onChange, ...field }, meta] = useField(name);

  return (<>
    <IonItem lines='none' >
      <IonLabel className="text-wrap">
        <h2>{label}</h2>
        {helpText && <p>{helpText}</p>}
        {!!(meta.error && meta.touched) && <IonNote color="danger" className="ion-padding-start"><small>{meta.error}</small></IonNote> }
      </IonLabel>
      <IonSelect interface={'action-sheet'} onIonChange={onChange} {...field}>
        <IonSelectOption disabled={false} value={'instant'}>Instant</IonSelectOption>
        <IonSelectOption disabled={false} value={'day'}>Normal</IonSelectOption>
        <IonSelectOption disabled={false} value={'week'}>Infrequent</IonSelectOption>
        <IonSelectOption disabled={false} value={'badge'}>No Alerts</IonSelectOption>
      </IonSelect>

    </IonItem>
  </>);
}

export function NotificationFrequencyInput2 ({ name, helpText, label }:
  { name: string, helpText?: string, label: JSX.Element | string }) {
  const [{ onChange, ...field }, meta] = useField(name);

  return (<>
      <IonItem lines='full'>
        <IonLabel className="text-wrap">
          <h2>{label}</h2>
          {helpText && <p>{helpText}</p>}
          {!!(meta.error && meta.touched) && <IonNote color="danger" className="ion-padding-start"><small>{meta.error}</small></IonNote> }
        </IonLabel>
        <IonSelect className='mw-100' interface={'action-sheet'} onIonChange={onChange} {...field}>
          <IonSelectOption disabled={false} value={'instant'}>Instant</IonSelectOption>
          <IonSelectOption disabled={false} value={'day'}>Normal</IonSelectOption>
          <IonSelectOption disabled={false} value={'week'}>Infrequent</IonSelectOption>
          <IonSelectOption disabled={false} value={'badge'}>No Alerts</IonSelectOption>
          <IonSelectOption disabled={false} value={'hide'}>Hidden</IonSelectOption>
        </IonSelect>
      </IonItem>
    {/* </IonItem> */}
  </>);
}

export function IonFormikInput ({ name, className = '', label, stackedLabel, helpText, ...props }:
  { name: string, className?: string, label?: JSX.Element, stackedLabel?: string, helpText?: string} & Parameters<typeof IonInput>[0]) {
  const [{ onChange, ...field }, meta] = useField(name);
  return (<>
    <IonItem lines={'full'}>
      {label && <IonLabel>{label}</IonLabel>}
      {stackedLabel && <IonLabel className="text-wrap" position="stacked">{stackedLabel}</IonLabel>}
      {helpText && <IonNote className="text-wrap small">{helpText}</IonNote>}
      <IonInput className={className} onIonChange={onChange} {...field} {...props}/>
    </IonItem>
    {!!(meta.error && meta.touched) && <IonItem lines={'none'}><IonLabel className={'mt-0'}><IonNote color="danger">{meta.error}</IonNote></IonLabel></IonItem> }
  </>);
}

export function IonFormikCheckbox ({ name, value, ...rest }: { name: string, value: string } & Parameters<typeof IonCheckbox>[0]) {
  const [fullField, { value: formValue }, { setValue }] = useField({ name, value, type: 'checkbox' });

  // eslint-disable-next-line unused-imports/no-unused-vars-ts
  const { onChange: ignore, ...field } = fullField;

  return (
    <IonCheckbox
      {...rest}
      {...field}
      onIonChange={(e) => {
        if (e.detail.checked) {
          setValue(formValue.concat([value]));
        } else {
          setValue(formValue.filter((i: string) => i !== value));
        }
      }}
    />
  );
}

const ACTIVATION_CODE_REGEX = /[^A-Z1-9]/g;
// const ACTIVATION_CODE_GROUP_REGEX = /.{1,5}/g;
export function IonActivationCodeInput ({ name, type, placeholder, disabled }:
  { name: string, type: Parameters<typeof IonInput>[0]['type'], placeholder?: string, disabled?: boolean }) {
  const [field, , helpers] = useField(name);
  // eslint-disable-next-line unused-imports/no-unused-vars-ts
  const { onChange: _, ...fieldRest } = field; // we use a custom onChange handler

  return (
    <IonInput
      {...field}
      className={'activation_code_input w-100 border rounded-1 px-2'}
      disabled={disabled}
      onIonChange={e => {
        let v = e.detail.value?.toUpperCase() || '';
        v = v.replace('0', 'O').replace(ACTIVATION_CODE_REGEX, '');
        // v = v.match(ACTIVATION_CODE_GROUP_REGEX)?.join(' '); Disabled because it's too confusing when people enter SPRANCER NOW
        helpers.setValue(v);
      }}
      type={type}
      placeholder={placeholder}/>);
}

export function IonFormikTextarea ({ name, stackedLabel, helpText, placeholder, autofocus = false, rows, lines }:
  { autofocus?: boolean, name: string, stackedLabel?: string, helpText?: string, placeholder: string, rows?: number, lines?: 'none' | 'inset' | 'full' }) {
  const [{ onChange, ...field }, meta] = useField(name);

  // Autofocus programatically.  Apparently autofocus doesn't work consistently in browsers: it doesn't work at all in
  // firefox and chrome only autofocuses the first time an autofocus in shown.
  const textAreaRef = useRef<HTMLIonTextareaElement>(null);
  useEffect(() => {
    if (autofocus) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      textAreaRef?.current?.setFocus();
    }
  }, [autofocus, textAreaRef]);

  return (<>
    <IonItem lines={lines}>
      {stackedLabel && <IonLabel className="text-wrap ion-padding-bottom-md" position="stacked">{stackedLabel}</IonLabel>}
      {helpText && <IonNote className="text-wrap">{helpText}</IonNote>}
      <IonTextarea ref={textAreaRef} autoGrow={true} autocapitalize={'on'} spellcheck={true} onIonChange={onChange} {...field} placeholder={placeholder} autofocus={autofocus} rows={rows}/>
    </IonItem>
    {!!(meta.error && meta.touched) && <IonNote color="danger" className="ion-padding-start">{meta.error}</IonNote> }
  </>);
}

export function IonFormikShortTextarea ({ name, placeholder, autofocus = false, rows }:
  { autofocus?: boolean, name: string, placeholder: string, rows?: number }) {
  const [{ onChange, ...field }, meta] = useField(name);

  // Autofocus programatically.  Apparently autofocus doesn't work consistently in browsers: it doesn't work at all in
  // firefox and chrome only autofocuses the first time an autofocus in shown.
  const textAreaRef = useRef<HTMLIonTextareaElement>(null);
  useEffect(() => {
    if (autofocus) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      textAreaRef?.current?.setFocus();
    }
  }, [autofocus, textAreaRef]);

  return (<>
    <IonTextarea ref={textAreaRef} autoGrow={true} autocapitalize={'on'} spellcheck={true} onIonChange={onChange} {...field} placeholder={placeholder} autofocus={autofocus} rows={rows} className={'short-text bg-white m-0 border'}/>
    {!!(meta.error && meta.touched) && <IonNote color="danger" className="ion-padding-start">{meta.error}</IonNote> }
  </>);
}

export function ColorSelectInput ({ name, label, placeholder }: {name: string, label?: JSX.Element, placeholder?: string}) {
  const [{ onChange, ...field }] = useField(name);
  return (<>
    <IonItem lines={'none'}>
      {label && <IonLabel>{label}</IonLabel>}
    </IonItem>
    <IonItem>
      <IonRadioGroup onIonChange={onChange} {...field} placeholder={placeholder}>
        <IonRow>
          {['bg-blue', 'bg-indigo', 'bg-purple', 'bg-pink', 'bg-red', 'bg-orange', 'bg-yellow', 'bg-green', 'bg-teal', 'bg-cyan'].map((c) => (
            <IonCol key={c} sizeXs='6' sizeMd='3'>
              <IonItem lines={'none'} >
                <IonLabel className={`${c}`}><div className={`${c} px-4`}>&nbsp;</div></IonLabel><IonRadio slot="start" value={c}/>
              </IonItem>
            </IonCol>
          ))}
        </IonRow>
      </IonRadioGroup>
    </IonItem>
  </>);
}

export function ImageFileDropInput ({ name, iconSize = '2.5em', existingImageUrl, removeExistingImage }:
  { name: string, iconSize?: string, existingImageUrl: string, removeExistingImage?: () => void}) {
  const [field,, helpers] = useField(name);
  const [error, setError] = useState(undefined);

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    helpers.setValue(acceptedFiles[0]);
    setError(fileRejections[0]?.errors[0]?.message);
  }, [helpers]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
    maxFiles: 1,
    disabled: !!existingImageUrl
  });

  function imagePreview (file: unknown) {
    const u = URL.createObjectURL(file);
    // Revoke the img url after the image is loaded to prevent memory leaks
    return (
      <>
        <img alt='Preview of upload' src={u} onLoad={() => URL.revokeObjectURL(u)} className={'mw-50 my-3'}/>
        <IonButton color='danger' slot="end" fill="clear" onClick={(e) => { e.stopPropagation(); helpers.setValue(undefined); }}>
          <DeleteIcon size='1.5em'/>
        </IonButton>
      </>
    );
  }

  function existingImagePreview () {
    return (
      <>
        <img alt='Preview of existing' src={existingImageUrl} className={'mw-50 my-3'}/>
        <IonButton color='danger' fill="clear" onClick={(e) => { e.stopPropagation(); removeExistingImage && removeExistingImage(); }}>
          <DeleteIcon size='1.5em'/>
        </IonButton>
      </>
    );
  }

  const rootProps = getRootProps({ className: 'dropzone' });
  delete rootProps.tabIndex;

  return (<>
    { error && <IonItem lines='none'>
      <IonLabel className="text-wrap">
        <IonNote color="danger" className="ion-padding-start"><small>{error}</small></IonNote>
      </IonLabel>
    </IonItem> }
    <div {...rootProps}>
      {/* invalid={'true'}> */}
      <input {...getInputProps()} />
      {
        existingImageUrl
          ? existingImagePreview()
          : field.value
            ? imagePreview(field.value)
            : <ImageIcon size={iconSize} />
      }
    </div>
  </>);
}

export function ShareRadioInput ({ name, label, helpText }: { name: string, label: string, helpText?: string }) {
  const [{ onChange, ...field }, meta] = useField(name);
  return (<>
    <IonItem lines='none'>
      <IonLabel className="text-wrap">
        <h2>{label}</h2>
        {helpText && <p>{helpText}</p>}
      </IonLabel>
    </IonItem>
    {!!(meta.error && meta.touched) && <IonNote color="danger" className="ion-padding-start"><small>{meta.error}</small></IonNote> }
    <IonRadioGroup onIonChange={onChange} {...field}>
      <IonRow>
      <IonItem lines={'none'}><IonLabel>Yes</IonLabel><IonRadio slot="start" value={'yes'}>Ye</IonRadio></IonItem>
      <IonItem lines={'none'}><IonLabel>No</IonLabel><IonRadio slot="start" value={'no'}/></IonItem>
      </IonRow>
    </IonRadioGroup>
  </>);
}

export function ToggleInput ({ name, onValue, offValue, label, helpText, disabled }:
  { name: string, onValue: string | boolean, offValue: string | boolean, label: string, helpText?: string, disabled?: boolean }) {
  const [field,, { setValue }] = useField({ name: name });

  return (<>
    <IonItem lines='none'>
      <IonToggle
        disabled={disabled}
        slot="end"
        name={name}
        checked={field.value === onValue}
        onIonChange={(e) => { setValue(e.detail.checked ? onValue : offValue); }} />
      <IonLabel className="text-wrap">
        <h2>{label}</h2>
        {helpText && <p>{helpText}</p>}
      </IonLabel>
    </IonItem>
  </>);
}

function flatten (obj: FormikErrors<FormikValues> | FormikTouched<FormikValues>, prefix?: string) {
  const flat: { [s: string]: string } = {};
  for (const [name, nestedOrMsg] of Object.entries(obj)) {
    const nameWithPrefix = prefix ? `${prefix}.${name}` : name;
    if (typeof nestedOrMsg !== 'object') {
      flat[nameWithPrefix] = nestedOrMsg;
    } else {
      for (const [prefixedName, msg] of Object.entries(flatten(nestedOrMsg, nameWithPrefix))) {
        flat[prefixedName] = msg;
      }
    }
  }
  return flat;
}

/**
 * Generic display for formik errors.  Use this at the top of a form to display any validation errors that
 * aren't expected, that way the customer won't end up in the annoying situation where they click submit and
 * the form just sits there because of missing error feedback.
 * @param expectedErrors a list of field names whose errors should not be shown in this component since they are
 * already displayed elsewhere by the form.
 * @constructor
 */
export function UnexpectedFormErrors ({ expectedErrors }: { expectedErrors: string[] }) {
  const ignored = new Set(expectedErrors);
  const { errors, status } = useFormikContext<FormikValues>();

  const flatErrors = flatten(errors);
  const unexpectedErrors = [];
  for (const [errorName, errorMsg] of Object.entries(flatErrors)) {
    if (!ignored.has(errorName)) {
      unexpectedErrors.push(`${errorName} - ${errorMsg}`);
    }
  }

  let formattedStatus = <></>;
  if (status) {
    formattedStatus = <IonItem className='ion-padding-bottom'><IonText color='danger'>{status}</IonText></IonItem>;
    if (unexpectedErrors.length === 0) {
      return (formattedStatus);
    }
  }

  if (unexpectedErrors.length === 1) {
    return (
      <>
        {formattedStatus}
        <p className='ion-padding-bottom'><IonText color='danger'>Unexpected Error: {unexpectedErrors[0]}</IonText></p>
      </>
    );
  } else if (unexpectedErrors.length > 1) {
    return (
      <>
        {formattedStatus}
        <p className='ion-padding-bottom'>
          <IonText color='danger'>Unexpected Errors:
            <ul>
              {unexpectedErrors.map((e) => (<li key={e}>{e}</li>))}
            </ul>
          </IonText>
        </p>
      </>
    );
  } else {
    return <></>;
  }
}
