import { BusinessContext, UserLayoutPage } from '../../containers/UserLayout';
import {
  IonBadge,
  IonButton,
  IonButtons, IonCol, IonGrid,
  IonItem,
  IonList,
  IonMenuButton,
  IonRow,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import { UserDropdown } from '../../components/Dropdowns';
import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { MissingSomethingWarning } from '../../components/Warnings';
import { DirectMessageThread } from '../../models/message';
import { AlertsContext } from '../../components/Alerts';
import { channels } from 'sprancer-shared';
import { AvatarImage } from '../../components/Avatars';
import { UserIcon } from '../../libs/icons';
import { MessageContentPreview } from '../../components/Messages';
import { GroupBadges } from '../../components/Groups';

export function DirectMessageLIst () {
  return (
    <UserLayoutPage
      header={<DirectMessageHeader/>}
      content={<DirectMessageContent/>}
    />
  );
}

function DirectMessageHeader () {
  return (
    <>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton autoHide={true}/>
        </IonButtons>
        <IonTitle>Direct Messages</IonTitle>
        <UserDropdown slot="end"/>
      </IonToolbar>
    </>
  );
}

function DirectMessageContent () {
  const { dmThreadsByIdAndChannelId } = useContext(BusinessContext);
  const { businessId } = useParams<{ businessId: string }>();

  return (
    <>
      <IonRow>
        <Link to={`/businesses/${businessId}/messages/new/new`} className='ml-auto mr-2 pt-2'>
          <IonButton size='default' color='primary'>
            New Message
          </IonButton>
        </Link>
      </IonRow>
      <IonList className='mb-2'>
        { dmThreadsByIdAndChannelId.size === 0
          ? <MissingSomethingWarning
            warningIcon={false}
            title={'No Messages'} />
          : Array.from(dmThreadsByIdAndChannelId.values(), thread => (
            <DirectMessageItem
              key={thread.threadId() + thread.channelId()}
              thread={thread}
            />
          ))
        }
      </IonList>
      <div className='text-muted small ion-text-center mb-2'>Messages expire after 30 days.</div>
    </>
  );
}

function DirectMessageItem ({ thread }:
  {
    thread: DirectMessageThread;
  }) {
  const { businessId } = useParams<{ businessId: string }>();
  const { pathToAlertIds } = useContext(AlertsContext);
  const { customersById, pubGroupsById, privGroupsById } = useContext(BusinessContext);

  const url = `/businesses/${businessId}/messages/${thread.threadId()}/channels/${thread.channelId()}`;
  const alertCount = pathToAlertIds.get(url)?.length;

  const customer = customersById.get(channels.getCustomerIdFromChannel(thread.channelId()) || '');
  const groups = thread.threadGroupIds()
    .filter(groupId => pubGroupsById.get(groupId) || customer?.groupIds.includes(groupId))
    .map(groupId => pubGroupsById.get(groupId) || privGroupsById.get(groupId));

  return (
    <IonItem className='m-0' routerLink={url} detail={true}
             button={true}>
      {alertCount && <IonBadge slot='end' color={'danger'}>{alertCount}</IonBadge>}
      <IonGrid className='px-0 overflow-hidden'>
        <IonRow>
          <IonCol>
            <div className='small text-muted text-nowrap'>{ thread.dateRange()}</div>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className='d-flex flex-wrap'>
            { customer
              ? <div className={'d-flex mr-1'}>
                <AvatarImage size='xs' avatar={customer?.avatar || ''}/>
                <span className='my-auto ml-1 text-truncate font-weight-bold small'>{customer?.nickname}</span>
              </div>
              : <div className='font-weight-bold small mr-1'><UserIcon/> DELETED CUSTOMER</div>
            }
            <GroupBadges groups={groups}/>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <MessageContentPreview message={ thread.lastMsg } showImage={false} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
}
