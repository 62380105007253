import { AmplifyResource } from './api';
import {
  alerts,
  schemas
} from 'sprancer-shared';
import { FetchOptions, Resource } from 'rest-hooks';
import { checkNever } from '../libs/assertions';

export interface AlertResource extends schemas.AlertType {}
export class AlertResource extends AmplifyResource {
  pk (): string | undefined {
    return this.id;
  }

  getReferenceFetchParams (): { businessId?: string, connectionId?: string, id: string } | undefined {
    let decomposed;
    switch (this.alertType) {
      case 'bizCustomerAlert':
        decomposed = alerts.decomposeBizCustomerAlertRefPath(this.referencePath);
        if (decomposed) {
          return { businessId: decomposed.businessId, id: decomposed.customerId };
        }
        break;
      case 'bizMessageAlert':
        decomposed = alerts.decomposeBizMessageAlertRefPath(this.referencePath);
        if (decomposed) {
          return { businessId: decomposed.businessId, id: decomposed.messageId };
        }
        break;
      case 'connMessageAlert':
        decomposed = alerts.decomposeConnMessageAlertRefPath(this.referencePath);
        if (decomposed) {
          return { connectionId: decomposed.bizPrefixedId, id: decomposed.messageId };
        }
        break;
      default:
        checkNever(this.alertType);
        return undefined;
    }
  }

  /** Used as default options for every FetchShape */
  static getFetchOptions (): FetchOptions {
    return {
      pollFrequency: 60000 // every 60 seconds
    };
  }

  static calcMessageHref (messageId: string, customerId?: string) {
    return customerId ? `/messages/${messageId}/customers/${customerId}` : `messages/${messageId}`;
  }

  static patchCollectionShape<T extends typeof Resource> (this: T) {
    return {
      ...super.partialUpdateShape(),
      fetch: (
        params: Readonly<Record<string, string>>,
        body: schemas.AlertPatchType
      ) => {
        return this.fetch('patch', this.listUrl(params), body).then(results => {
          if (body.action === 'clearNotify') {
            // the API removes the notifyAt field completely from each record, but unfortunately the rest-hooks cache
            // doesn't detect that it has been removed.  Add back the notifyAt field as undefined so the cache can
            // update properly.
            for (const alert of results) {
              alert.notifyAt = undefined;
            }
          }
          return results;
        });
      },
      schema: [this.asSchema()]
    };
  }

  static urlRoot = '/alerts';
}
