import { useEffect, useState } from 'react';
import { isPlatform } from '@ionic/react';
import { AppState, Plugins } from '@capacitor/core';
import { useFetcher } from 'rest-hooks';
import { UserResource } from '../models/user';
import { UserPatchType } from 'sprancer-shared/dist/schemas';
import { reportException } from './errors';
import { dates } from 'sprancer-shared';
const { App, Device, Keyboard } = Plugins;

export function useAppIsActive () {
  const [isActive, setIsActive] = useState(true);
  useEffect(() => {
    if (isPlatform('hybrid')) {
      const handle = App.addListener('appStateChange', (state: AppState) => {
        setIsActive(state.isActive);
      });

      // clean up the listener
      return handle.remove;
    }
  }, []); // Empty deps because we only want this to fire once

  return isActive;
}

export function useKeyboardDidShowCount () {
  const [didShowCount, setDidShowCount] = useState(0);

  useEffect(() => {
    if (isPlatform('hybrid')) {
      const showListener = Keyboard.addListener('keyboardDidShow', () => {
        setDidShowCount(c => c + 1);
      });

      // return the cleanup handler
      return () => {
        showListener.remove();
      };
    }
  }, [setDidShowCount]);

  return didShowCount;
}

export function useUserDeviceInfo (user: UserResource) {
  const userId = user.userId;

  const patchUser = useFetcher(UserResource.partialUpdateShape());

  const userPlatform = user.platform;
  const userAppVersion = user.appVersion;
  // Get the users timezone so we can turn disable sounds for push notifications that go out in the middle of the night.
  const userTzOffset = user.timezoneOffset;

  const date = dates.newISODate(); // add the date string to the effect to make sure the effect executes at least once a day

  const tzOffset = new Date().getTimezoneOffset();
  useEffect(() => {
    Device.getInfo().then(di => {
      const diAppVersion = di.appVersion ? `biz${di.appVersion}b${di.appBuild}` : '';

      let shouldPatch = false;
      const values: UserPatchType = {};
      if (di.platform !== userPlatform) {
        values.platform = di.platform;
        shouldPatch = true;
      }
      if (diAppVersion !== userAppVersion) {
        values.appVersion = diAppVersion;
        shouldPatch = true;
      }
      if (tzOffset !== userTzOffset) {
        values.timezoneOffset = tzOffset;
        shouldPatch = true;
      }

      if (shouldPatch) {
        patchUser({ id: userId }, values).catch(e => {
          // Unexpected.  Not critical.  Report and ignore.
          reportException(e, 'patchUser failed in capacitorApp useUserDeviceInfo useEffect');
        });
      }
    }).catch(e => {
      reportException(e, 'Device.getInfo failed in capacitorApp useUserDeviceInfo useEffect');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, date]);
}
