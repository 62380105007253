import {
  IonBadge, IonButton,
  IonButtons,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonNote,
  IonTitle,
  IonToolbar, isPlatform
} from '@ionic/react';
import React, { useContext } from 'react';
import { UserLayoutPage } from '../../containers/UserLayout';
import { useGetCurrentUser } from '../../models/user';
import { AlertsContext } from '../../components/Alerts';
import * as lo from 'lodash';
import { UserDropdown } from '../../components/Dropdowns';
import { Logo } from '../../components/Logos';
import { AvatarWithName } from '../../components/Avatars';
import { useResource } from 'rest-hooks';
import { BusinessResource } from '../../models/business';
import NotFound404ErrorBoundary from '../Errors/NotFound404ErrorBoundary';
import { useBackground } from '../../libs/background';
import { strings } from 'sprancer-shared';
import * as APP_STORE_BADGE from '../../assets/img/appstorebadge.png';
import * as GOOGLE_PLAY_BADGE from '../../assets/img/googleplaybadge.png';
import { usePrefersDarkMode } from '../../libs/mediaQueries';
import { Link } from 'react-router-dom';

export default function UserHome () {
  const { contentStyle } = useBackground();

  return (
    <UserLayoutPage
      header={<Header/>}
      content={<Content/>}
      // turn off the ionic shadow '--background' varible and use a css variable.  --background was flickering on ios.
      contentStyle={contentStyle}
    />
  );
}

function Header () {
  return (
    <IonToolbar>
      <IonButtons slot="start">
        <IonMenuButton autoHide={true}/>
      </IonButtons>
      <IonTitle>Home</IonTitle>
      <UserDropdown slot="end"/>
    </IonToolbar>
  );
}

const BUSINESS_PATH_REGEX = /\/businesses\/([^/]+)/;

function calcConnectedSentence (bizCount: number): string {
  if (bizCount > 0) {
    return `You own ${pluralizeBusiness(bizCount)}.`;
  } else {
    return 'You have not setup any businesses.';
  }
}

function pluralizeBusiness (count: number): string {
  return count === 1 ? 'one business' : `${strings.wordenizeCount(count)} businesses`;
}

function Content () {
  const user = useGetCurrentUser();

  const { pathToAlertIds } = useContext(AlertsContext);

  const businessPerms = lo.sortBy(user?.permissions || [], ['name']).filter(p => p.path.startsWith('/businesses/'));

  if (businessPerms.length === 0) {
    return <WelcomeContent />;
  }

  return (<>
    <IonItem color='transparent' lines='none'>
      <h4 className={'text-muted text-break'}>Hello{user.nickname ? ' ' + user.nickname : ''},</h4>
    </IonItem>
    <IonItem color='transparent' lines='none' className={'mb-4'}>
      <IonLabel>
        <p className={'text-wrap text-muted'}>
          {`Sprancer makes it easy for your customers to connect to your business without worrying about sharing their email or phone number.  ${calcConnectedSentence(businessPerms.length)}`}
        </p>
      </IonLabel>
    </IonItem>

    {businessPerms.length > 0 && <IonList className={'mb-5 py-0'}>
      <IonItem lines={'full'}>
        <IonNote className={'py-3'}> <strong>Message your customers</strong></IonNote>
      </IonItem>
      {businessPerms.map(p => {
        const businessId = BUSINESS_PATH_REGEX.exec(p.path)?.[1];
        return (businessId &&
          <NotFound404ErrorBoundary key={p.path} fallback={<IonItem lines='full'><IonNote className={'py-3'}><strong>{p.name}</strong> has been removed.</IonNote></IonItem>}>
            <BusinessCard businessId={businessId} pathToAlertIds={pathToAlertIds}/>
          </NotFound404ErrorBoundary>
        );
      })}
    </IonList>}

    <AppBadges />
  </>);
}

function BusinessCard ({ businessId, pathToAlertIds }: { businessId: string, pathToAlertIds: Map<string, string[]> }) {
  const path = `/businesses/${businessId}`;
  const alertCount = pathToAlertIds.get(path)?.length;

  const business = useResource(BusinessResource.detailShape(), { id: businessId });
  if (!business) {
    return (<></>);
  }

  return (
    <IonItem className='' lines='none' routerLink={path} detail={true} button={true}>
      {alertCount && <IonBadge slot='end' color={'danger'}>{alertCount}</IonBadge>}
      {/* <IonLabel>{business.businessName} <IonBadge color={'warning'}>My Business</IonBadge></IonLabel> */}
      <div className={'py-3'}>
        <div className={'d-flex'}>
          <div className={'mr-1'}>
            {business.logoImageUrl
              ? <><Logo url={business.logoImageUrl} size={'md'}/></>
              : <AvatarWithName avatar={business.avatar} name={<>{business.fullName()}</>}/>}
          </div>
          <div><IonBadge color={'warning'}>My Business</IonBadge></div>
        </div>
      </div>
    </IonItem>
  );
}

function WelcomeContent () {
  const user = useGetCurrentUser();

  const darkmode = usePrefersDarkMode();

  return (<>
    <IonItem color='transparent' lines='none'>
      <h4 className={'text-muted text-break'}>Hello{user.nickname ? ' ' + user.nickname : ''},</h4>
    </IonItem>
    <IonItem color='transparent' lines='none' className={'mb-3'}>
      <IonLabel>
        <p className={'text-wrap text-muted'}>
          Sprancer makes it easy for your customers to connect to your business without worrying about sharing their email or phone number.
        </p>
      </IonLabel>
    </IonItem>
    <IonItem color='transparent' lines='none' className={'mb-3'}>
      <IonLabel>
        <p className={'text-wrap text-muted'}>To get your business on Sprancer, get in touch!
          { user.connectToSprancerPath && <> <strong>Connect with us on Sprancer</strong>, or</> }
          <strong> Email us at support@sprancer.com.</strong>
        </p>
      </IonLabel>
    </IonItem>
    {user.connectToSprancerPath &&
    <IonItem color='transparent' lines='none' className={'mb-3'}>
      <IonLabel>
        <IonButton size={'default'} className='text-wrap' expand={'block'}
                   href={`https://app.sprancer.com${user.connectToSprancerPath}`}>Connect With Sprancer</IonButton>
      </IonLabel>
    </IonItem>
    }
    { !isPlatform('hybrid') &&
      <div className={`text-center mb-4 p-3 ${darkmode ? 'text-muted' : 'bg-white-half-transparent'}`}>
        <Link to={'/user/account/redeemCode'}>Have an invitation code? Enter it here.</Link>
      </div>
    }
    <AppBadges />

  </>);
}

function AppBadges () {
  if (isPlatform('hybrid')) {
    return (<></>);
  }

  return (<div className={'mb-3'}>
    <p className={'ion-text-center text-muted'}>Sprancer works better on your phone!</p>
    <div className={'d-flex ion-justify-content-center'}>
      <a className='px-3' href="https://apps.apple.com/us/app/sprancer/id1545872777">
        <img src={APP_STORE_BADGE.default}
             alt="Download on the App Store"
             height={38}
        />
      </a>
      <a className='px-3' href='https://play.google.com/store/apps/details?id=com.sprancer.app'>
        <img src={GOOGLE_PLAY_BADGE.default}
             alt='Get it on Google Play'
             height={38}
        />
      </a>
    </div>
  </div>);
}
