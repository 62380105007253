import React, { useContext } from 'react';
import { MessageResource } from '../../models/message';
import { schemas } from 'sprancer-shared';
import { s3UploadMessageImage } from '../../libs/storage';
import { useFetcher } from 'rest-hooks';
import { reportException } from '../../libs/errors';
import { useHistory, useParams } from 'react-router';
import { FormikHelpers, Formik, Form } from 'formik';
import { ImageFileDropInput, IonFormikTextarea, UnexpectedFormErrors } from '../../components/Forms';
import { TextSaveButton } from '../../components/FormButtons';
import { BusinessContext, UserLayoutPage } from '../../containers/UserLayout';
import { IonBackButton, IonButtons, IonItem, IonLabel, IonTitle, IonToolbar, IonNote } from '@ionic/react';
import { UserDropdown } from '../../components/Dropdowns';
import { GroupCheckInput } from '../../components/Groups';
import { genBusinessChannelId } from 'sprancer-shared/dist/channels';
import { MissingSomethingWarning } from '../../components/Warnings';
import * as yup from 'yup';
import { WarningIcon } from '../../libs/icons';

export default function NewPost () {
  return (
    <UserLayoutPage
      header={<Header/>}
      content={<Content/>}
    />
  );
}

function Header () {
  const { businessId } = useParams<{ businessId: string }>();

  return (
    <IonToolbar>
      <IonButtons slot="start">
        <IonBackButton text='Cancel' defaultHref={`/businesses/${businessId}/posts`}/>
      </IonButtons>
      <IonTitle>New Post</IonTitle>
      <UserDropdown slot="end"/>
    </IonToolbar>
  );
}

// create a subset of MessageCreateSchema the requires at least one groupId.
export const PostMessageCreateSchema = yup.object({
  channelId: yup.string().required(), // bizZ<businessId> zcstZ<customerId>
  groupIds: yup.array().of(yup.string().required()).default([]).required(),

  text: yup.string().required().default(''),
  s3ImageName: yup.string().notRequired()
});

function Content () {
  const create = useFetcher(MessageResource.createShape());
  const { businessId } = useParams<{ businessId: string }>();
  const history = useHistory();

  const { pubGroupsById, privGroupsById, groupToCustomerIds } = useContext(BusinessContext);
  const groupsById = new Map(pubGroupsById);
  privGroupsById.forEach((v, k) => groupsById.set(k, v));

  if (groupsById.size === 0) {
    return (
      <MissingSomethingWarning
        title={'No Groups'}
        message={'Please create a group before sending a post.'}
        buttonLink={`/businesses/${businessId}/groups/new/new`}
        buttonTitle={'New Group'} />
    );
  }

  async function handleSubmit (values: schemas.MessageCreateType & { imageFile?: File }, actions: FormikHelpers<schemas.MessageCreateType>) {
    const { imageFile, ...createValues } = values;
    if (imageFile) {
      try {
        createValues.s3ImageName = await s3UploadMessageImage(imageFile, { shouldResize: 'true' });
      } catch (e) {
        // image upload failed report exception and return from the function early
        reportException(e, 's3UploadMessageImage failed in NewPost Content handleSubmit');
        actions.setStatus(e.message || e);
        actions.setSubmitting(false);
        return;
      }
    }
    await create({ businessId }, createValues, [
      [MessageResource.listShape(), { businessId }, (id, ids) => [...(ids || []), id]]
    ]).then(async () => {
      actions.setSubmitting(false);
      history.replace(`/businesses/${businessId}/posts`);
    }).catch((e) => {
      reportException(e, 'create failed in NewPost Content handleSubmit');
      actions.setStatus(e.message || e);
      actions.setSubmitting(false);
    });
  }

  const initialValues: schemas.MessageCreateType & { imageFile?: File } = PostMessageCreateSchema.required().default();
  initialValues.channelId = genBusinessChannelId(businessId);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={PostMessageCreateSchema}
    >
      {({ isSubmitting, dirty, values }) => (
        <Form>
          <UnexpectedFormErrors expectedErrors={['groupIds', 'text']}/>
          <GroupCheckInput name={'groupIds'} label={'Groups'} groupsById={groupsById} groupToCustomerIds={groupToCustomerIds} />
          { hasGroupButNoCustomers(groupToCustomerIds, values.groupIds) &&
            <IonItem lines={'none'}><IonNote><WarningIcon color={'warning'}/> The selected group(s) have no customers.  Remember to add them later.</IonNote></IonItem>
          }
          <IonItem lines={'none'}><IonLabel>Message</IonLabel></IonItem>
          <IonItem lines={'none'}><ImageFileDropInput name='imageFile' existingImageUrl='' /></IonItem>
          <IonFormikTextarea autofocus name='text' placeholder='Enter a new message' rows={3}/>
          <IonItem lines="none" className='text-center'>
            <IonLabel>
              <TextSaveButton text='Send' loadingText='Sending…' disabled={isSubmitting || !dirty} isLoading={isSubmitting} />
            </IonLabel>
          </IonItem>
        </Form>
      )}
    </Formik>
  );
}

function hasGroupButNoCustomers (groupToCustomerIds: Map<string, Set<string>>, groupIds: string[]) {
  if (groupIds.length === 0) {
    return false;
  }

  for (const groupId of groupIds) {
    if ((groupToCustomerIds.get(groupId)?.size || 0) > 0) {
      return false;
    }
  }
  return true;
}
