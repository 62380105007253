import { Redirect, Route } from 'react-router';
import React from 'react';
import UserProfileOverview from './UserProfileOverview';
import UserProfileDetailsEdit from './UserProfileDetailsEdit';
import { Switch } from 'react-router-dom';
import { IonRouterOutletWrapper } from '../../components/RouterOutlet';

export default function UserProfile () {
  // IonRouterOutletWrapper for an explanation of this nonsense.
  const routes = [
    { path: '/user/profile/editDetails', name: 'Edit Profile', component: <UserProfileDetailsEdit /> },
    { path: '/user/profile', name: 'Edit Profile', component: <UserProfileOverview /> }
  ];

  return (
    <Switch>
      <Route exact path={routes[0].path} name={routes[0].name}><IonRouterOutletWrapper outletRoutes={routes}/></Route>
      <Route exact path={routes[1].path} name={routes[1].name}><IonRouterOutletWrapper outletRoutes={routes}/></Route>
      <Redirect to={'/user/profile'} />
    </Switch>
  );
}
