import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import {
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonToolbar,
  IonRow,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonListHeader, IonBadge, IonSearchbar
} from '@ionic/react';
import { UserDropdown } from '../../components/Dropdowns';
import { BusinessContext, UserLayoutPage } from '../../containers/UserLayout';
import { AvatarImage } from '../../components/Avatars';
import { MissingSomethingWarning } from '../../components/Warnings';
import { PrivateGroupIcon, UserIcon } from '../../libs/icons';
import { AlertsContext } from '../../components/Alerts';
import { GroupResource } from '../../models/group';

export default function Posts () {
  return (
    <UserLayoutPage
      header={<Header/>}
      content={<Content/>}
    />
  );
}

function Header () {
  return (
    <>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton autoHide={true}/>
        </IonButtons>
        <IonTitle>Customers</IonTitle>
        <UserDropdown slot="end" />
      </IonToolbar>
    </>
  );
}

function Content () {
  const { businessId } = useParams<{ businessId: string }>();

  return (
    <>
      <IonRow>
        <Link to={`/businesses/${businessId}/groups/new/new`} className='ml-auto mr-2 pt-2'>
          <IonButton size='default' color='primary'>
            New Group
          </IonButton>
        </Link>
        <Link to={`/businesses/${businessId}/customers/new/new`} className='mr-2 pt-2'>
          <IonButton size='default' color='primary'>
            New Customer
          </IonButton>
        </Link>
      </IonRow>

      <IonListHeader>Groups</IonListHeader>
      <GroupsSectionList />
      <IonListHeader>Customers</IonListHeader>
      <CustomersSectionList />
    </>
  );
}

function GroupsSectionList () {
  const { pubGroupsById, privGroupsById } = useContext(BusinessContext);

  if (privGroupsById.size === 0 && pubGroupsById.size === 0) {
    return (<MissingSomethingWarning warningIcon={false} title={'No Groups'} />);
  }

  return (<>
    <IonList>
      { Array.from(pubGroupsById.values(), group => <GroupItem key={group.id} group={group}/>) }
      { Array.from(privGroupsById.values(), group => <GroupItem key={group.id} group={group}/>) }
    </IonList>
  </>);
}

function GroupItem ({ group }: { group: GroupResource }) {
  const { businessId } = useParams<{ businessId: string }>();
  const { groupToCustomerIds } = useContext(BusinessContext);

  return (
    <IonItem key={group.id} routerLink={`/businesses/${businessId}/groups/${group.id}`}>
      <IonBadge className={`${group.color} text-wrap`}>{group.name}{group.priv &&
      <PrivateGroupIcon className={'ml-1'}/>}</IonBadge>
      <span slot='end'
            className='text-small text-muted text-nowrap'> <UserIcon/> {groupToCustomerIds.get(group.id)?.size || 0}</span>
    </IonItem>
  );
}

function CustomersSectionList () {
  const { businessId } = useParams<{ businessId: string }>();
  const { customersById, pubGroupsById, privGroupsById } = useContext(BusinessContext);

  const [searchRegex, setSearchRegex] = useState<RegExp | null>(null);

  const { pathToAlertIds } = useContext(AlertsContext);

  if (customersById.size === 0) {
    return (<>
      <IonList>
        <MissingSomethingWarning warningIcon={false} title={'No Customers'}/>
      </IonList>
    </>);
  }

  return (<>
      <IonItem lines={'none'}><IonSearchbar onIonChange={e => setSearchRegex(new RegExp(e.detail.value!, 'i'))} /></IonItem>
      <IonList>
        {Array.from(customersById.entries(), ([customerId, customer]) => {
          if (searchRegex && !customer.nickname.match(searchRegex)) {
            return (<></>);
          }

          const customerPath = `/businesses/${businessId}/customers/${customerId}`;
          const alertCount = pathToAlertIds.get(customerPath)?.length;

          return (
            <IonItem key={customer.id} routerLink={customerPath}>
              <AvatarImage slot='start' className='w-100 avatar-auto' size='md' status={customer.status} avatar={customer.avatar} />
              <IonLabel><h2 className='text-break'>{customer.nickname || <span className={'text-muted'}>No Name</span>}</h2></IonLabel>
              { alertCount && <IonBadge slot='end' color={'danger'}>new</IonBadge>}
              <div slot='end'>
                { Array.from(pubGroupsById.values(), g => <IonBadge key={g.id} mode={'ios'} className={`border border-white ${g.color} mr-n3`}>&nbsp;</IonBadge>)}
                { customer.groupIds.map(groupId => {
                  const g = privGroupsById.get(groupId);
                  if (g) {
                    return <IonBadge key={groupId} mode={'ios'} className={`border border-white ${g.color} mr-n3`}>&nbsp;</IonBadge>;
                  } else {
                    return '';
                  }
                })}
              </div>
            </IonItem>
          );
        })}
      </IonList>
    </>
  );
}
