import { useGetCurrentUser, UserResource } from '../../models/user';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAppContext } from '../../libs/context';
import { useFetcher, useInvalidator } from 'rest-hooks';
import { BusinessResource } from '../../models/business';
import { useHistory } from 'react-router';
import { schemas } from 'sprancer-shared';
import { Form, Formik, FormikHelpers } from 'formik';
import { reportException } from '../../libs/errors';
import {
  AvatarRadioInput,
  ImageFileDropInput,
  IonFormikInput,
  IonFormikTextarea,
  UnexpectedFormErrors
} from '../../components/Forms';
import { TextSaveButton } from '../../components/FormButtons';
import { UserLayoutPage } from '../../containers/UserLayout';
import {
  IonBackButton,
  IonButtons,
  IonItem, IonLabel,
  IonList,
  IonTitle,
  IonToolbar,
  IonListHeader,
  IonNote
} from '@ionic/react';
import { UserDropdown } from '../../components/Dropdowns';
import { s3UploadBusinessLogo } from '../../libs/storage';

export default function UserNewBusiness () {
  return (
    <UserLayoutPage
      header={<Header/>}
      content={<Content />}
    />
  );
}

function Header () {
  return (
    <IonToolbar>
      <IonButtons slot="start">
        <IonBackButton text='Cancel' defaultHref={'/user/home'}/>
      </IonButtons>
      <IonTitle>New Business</IonTitle>
      <UserDropdown slot="end"/>
    </IonToolbar>
  );
}

function Content () {
  const { authenticated } = useAppContext();

  const history = useHistory();

  const user = useGetCurrentUser();
  const patch = useFetcher(UserResource.partialUpdateShape());

  const create = useFetcher(BusinessResource.createShape());
  const invalidate = useInvalidator(UserResource.detailShape());

  if (!authenticated) {
    return <Redirect to={{ pathname: '/login/create', state: { businessPasswordRequired: true } }} />;
  }

  async function handleSubmit (values: schemas.BusinessCreateType & { imageFile?: File }, actions: FormikHelpers<schemas.BusinessCreateType>) {
    try {
      if (values.imageFile) {
        values.logoS3ImageName = await s3UploadBusinessLogo(values.imageFile, { shouldResize: 'true' });
      }
      if (!user.nickname) {
        // the user hasn't set a name yet.  Use the contact name the provided to update their user profile.
        await patch({ id: user.userId }, { nickname: values.contactName });
      }
      const business = await create({}, values);
      invalidate({ id: 'current' });
      actions.setSubmitting(false);
      history.replace(`/businesses/${business.id}`);
    } catch (e) {
      if (e.response?.status === 403) {
        actions.setStatus('You have already created a business.');
      } else {
        reportException(e, 'handleSubmit failed in UserNewBusiness Content');
        actions.setStatus(e.message || e);
        actions.setSubmitting(false);
      }
    }
  }

  const initialValues: schemas.BusinessCreateType = {
    contactName: '',
    businessName: '',
    avatar: '',
    welcomeMessage: 'Hi!  I\'m using Sprancer to keep in touch with our customers.  Send a message if you have any questions or just to say hi!'
  };

  return (<>
    <IonList>
      <IonListHeader>
        <IonLabel>Create A New Business</IonLabel>
      </IonListHeader>
      <IonListHeader>
        <IonNote className={'small'}>Enter a few details about yourself. These will be shared with your customers.</IonNote>
      </IonListHeader>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={schemas.BusinessCreateSchema}
      >
        {({ isSubmitting, dirty, values, setFieldValue }) => (
          <Form>
            <UnexpectedFormErrors expectedErrors={['contactName', 'businessName', 'avatar', 'welcomeMessage']}/>
            <IonFormikInput
              name='contactName'
              stackedLabel={'Contact Name'}
              placeholder={'Contact Name'}
              autocomplete={'given-name'}
              autocapitalize={'words'}
              type='text'
            />
            <IonFormikInput
              name='businessName'
              stackedLabel={'Business Name'}
              placeholder={'Business Name'}
              autocapitalize={'words'}
              type='text'
            />
            <IonItem lines={'none'}><IonLabel position={'stacked'}>Logo</IonLabel></IonItem>
            <IonItem>
              <ImageFileDropInput
                name='imageFile'
                existingImageUrl={values.logoS3ImageName || ''}
                removeExistingImage={() => setFieldValue('logoS3ImageName', '')}
              />
            </IonItem>
            <AvatarRadioInput name={'avatar'} stackedLabel={'Avatar'}/>
            <IonFormikTextarea
              name='welcomeMessage'
              stackedLabel={'Welcome Message'}
              helpText='Customers see this when they connect.'
              placeholder='Welcome Message'
              rows={6}
            />
            <IonItem lines="none" className='text-center'>
              <IonLabel>
                <TextSaveButton disabled={isSubmitting || !dirty} isLoading={isSubmitting} />
              </IonLabel>
            </IonItem>
          </Form>
        )}
      </Formik>
    </IonList>
    </>
  );
}
