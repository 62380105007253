import {
  IonBackButton,
  IonButtons,
  IonItem, IonLabel,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import { UserDropdown } from '../../components/Dropdowns';
import { useHistory, useParams } from 'react-router';
import React, { useContext } from 'react';
import { BusinessContext, UserLayoutPage } from '../../containers/UserLayout';
import { useFetcher, useResource } from 'rest-hooks';
import { Form, Formik, FormikHelpers } from 'formik';
import { reportException } from '../../libs/errors';
import { schemas } from 'sprancer-shared';
import { IonFormikTextarea, UnexpectedFormErrors } from '../../components/Forms';
import { TextSaveButton } from '../../components/FormButtons';
import { CustomerResource } from '../../models/customer';
import { GroupCheckInput } from '../../components/Groups';
import { AvatarImage } from '../../components/Avatars';

export default function CustomerDetailsEdit () {
  return (
    <UserLayoutPage
      header={<Header/>}
      content={<Content />}
    />
  );
}

function Header () {
  const { businessId, customerId } = useParams<{ businessId: string, customerId: string }>();

  return (
    <>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton text='Cancel' defaultHref={`/businesses/${businessId}/customers/${customerId}`}/>
        </IonButtons>
        <IonTitle>Edit Customer</IonTitle>
        <UserDropdown slot="end" />
      </IonToolbar>
    </>
  );
}

function Content () {
  const { businessId, customerId } = useParams<{ businessId: string, customerId: string }>();
  const { privGroupsById } = useContext(BusinessContext);

  const customer = useResource(CustomerResource.detailShape(), { businessId, id: customerId });
  const update = useFetcher(CustomerResource.updateShape());

  const history = useHistory();

  async function handleSubmit (values: CustomerResource, actions: FormikHelpers<CustomerResource>) {
    try {
      await update({ businessId: businessId, id: customer.id }, values);
      actions.setSubmitting(false);
      history.goBack();
    } catch (e) {
      reportException(e, 'handleSubmit failed in CustomerDetailsEdit Content');
      actions.setStatus(e.message || e);
      actions.setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={customer}
      onSubmit={handleSubmit}
      validationSchema={schemas.CustomerUpdateSchema}
    >
      {({ isSubmitting, dirty }) => (
        <Form>
          <IonItem lines='none'>
            <AvatarImage slot='start' size='lg' status={customer.status} avatar={customer.avatar} />
            <IonLabel className={'text-wrap'}>
              <h2>{customer.nickname}</h2>
            </IonLabel>
          </IonItem>

          <UnexpectedFormErrors expectedErrors={['groupIds', 'notes']}/>
          <IonItem lines={'none'}><IonLabel>Notes</IonLabel></IonItem>
          <IonFormikTextarea
            name='notes'
            placeholder='Enter any notes about this customer'
            rows={2}
          />
          <GroupCheckInput name={'groupIds'} label={'Private Groups'} groupsById={privGroupsById}/>
          <IonItem lines="none" className='text-center'>
            <IonLabel>
              <TextSaveButton disabled={isSubmitting || !dirty} isLoading={isSubmitting} />
            </IonLabel>
          </IonItem>
        </Form>
      )}
    </Formik>
  );
}
