import React, { useContext, useState } from 'react';
import { channels } from 'sprancer-shared';
import { useParams } from 'react-router';
import { BusinessContext, UserLayoutPage } from '../../containers/UserLayout';
import {
  IonBackButton,
  IonButtons,
  IonItem,
  IonLabel,
  IonList,
  IonSearchbar,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import { UserDropdown } from '../../components/Dropdowns';
import { AvatarImage } from '../../components/Avatars';
import { MissingSomethingWarning } from '../../components/Warnings';
import { Link } from 'react-router-dom';

export default function NewDirectMessage () {
  return (
    <UserLayoutPage
      header={<Header/>}
      content={<Content/>}
    />
  );
}

function Header () {
  const { businessId } = useParams<{ businessId: string }>();

  return (
    <IonToolbar>
      <IonButtons slot="start">
        <IonBackButton defaultHref={`/businesses/${businessId}/messages`}/>
      </IonButtons>
      <IonTitle>New Direct Message</IonTitle>
      <UserDropdown slot="end"/>
    </IonToolbar>
  );
}

function Content () {
  const { businessId } = useParams<{ businessId: string }>();
  const { customersById } = useContext(BusinessContext);

  const [searchRegex, setSearchRegex] = useState<RegExp | null>(null);

  if (customersById.size === 0) {
    return (
      <MissingSomethingWarning
        title={'No Customers'}
        message={'Please connect to a customer to enable direct messaging.'}
        buttonLink={`/businesses/${businessId}/customers/new/new`}
        buttonTitle={'New Customer'} />
    );
  }

  return (
    <>
      <IonItem lines={'none'}><IonSearchbar onIonChange={e => setSearchRegex(new RegExp(e.detail.value!, 'i'))} /></IonItem>
      <IonItem lines={'none'}><IonLabel className='text-wrap'>Choose a customer:</IonLabel></IonItem>
      <IonList>
        {Array.from(customersById.entries(), ([customerId, customer]) => {
          if (searchRegex && !customer.nickname.match(searchRegex)) {
            return (<></>);
          }

          return (
            <Link key={customerId} to={{
              pathname: `/businesses/${businessId}/messages/${channels.genDirectThreadId(customerId)}/channels/${channels.genDirectChannelId(customerId)}`,
              state: { newMessage: true }
            }}>
              <IonItem detail={true}>
                <AvatarImage slot='start' className='w-100 avatar-auto' size='md' status={customer.status} avatar={customer.avatar} />
                <IonLabel><h2 className='text-break'>{customer.nickname || <span className={'text-muted'}>No Name</span>}</h2></IonLabel>
              </IonItem>
            </Link>
          );
        })}
      </IonList>
    </>
  );
}
