import { BusinessContext, UserLayoutPage } from '../../containers/UserLayout';
import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonItem,
  IonList,
  IonMenuButton,
  IonRow,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import { UserDropdown } from '../../components/Dropdowns';
import { Link } from 'react-router-dom';
import React, { useContext } from 'react';
import { AlertsContext } from '../../components/Alerts';
import { MessageContentPreview } from '../../components/Messages';
import { DirectMessagesIcon, ReactionsIcons, UsersIcon } from '../../libs/icons';
import { useParams } from 'react-router';
import { PostThread } from '../../models/message';
import { GroupBadges } from '../../components/Groups';
import { calculateCustomerIds } from './messageHelpers';
import { MissingSomethingWarning } from '../../components/Warnings';

//
// Posts
//

export function PostList () {
  return (
    <UserLayoutPage
      header={<PostHeader/>}
      content={<PostContent/>}
    />
  );
}

function PostHeader () {
  return (
    <>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton autoHide={true}/>
        </IonButtons>
        <IonTitle>Posts</IonTitle>
        <UserDropdown slot="end"/>
      </IonToolbar>
    </>
  );
}

function PostContent () {
  const { postThreadsById } = useContext(BusinessContext);
  const { businessId } = useParams<{ businessId: string }>();

  return (
    <>
      <IonRow>
        <Link to={`/businesses/${businessId}/posts/new/new`} className='ml-auto mr-2 pt-2'>
          <IonButton size='default' color='primary'>
            New Post
          </IonButton>
        </Link>
      </IonRow>
      <IonList className='mb-2'>
        { postThreadsById.size === 0
          ? <MissingSomethingWarning
            warningIcon={false}
            title={'No Posts'} />
          : Array.from(postThreadsById.values(), thread => (
            <PostItem
              key={thread.threadId()}
              thread={thread}
            />
          ))
        }
      </IonList>
      <div className='text-muted small ion-text-center mb-2'>Messages expire after 30 days.</div>
    </>
  );
}

//
// Common
//

function PostItem ({ thread }:
  {
    thread: PostThread;
  }) {
  const { businessId } = useParams<{ businessId: string }>();
  const { pathToAlertIds } = useContext(AlertsContext);
  const url = `/businesses/${businessId}/posts/${thread.threadId()}`;
  const alertCount = pathToAlertIds.get(url)?.length;

  const { pubGroupsById, privGroupsById, groupToCustomerIds } = useContext(BusinessContext);
  const groups = thread.threadGroupIds().map(groupId => pubGroupsById.get(groupId) || privGroupsById.get(groupId));

  return (
    <IonItem className='m-0' routerLink={url} detail={true}
             button={true}>
      {alertCount && <IonBadge slot='end' color={'danger'}>{alertCount}</IonBadge>}
      <IonGrid className='px-0 overflow-hidden'>
        <IonRow>
          <IonCol>
            <div className='small text-muted text-nowrap'>{ thread.dateRange()}</div>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className='d-flex flex-wrap'>
            <>
              <div className='small text-muted mr-1'>
                <UsersIcon/> {calculateCustomerIds(thread.threadGroupIds(), groupToCustomerIds).length}
              </div>
              <GroupBadges groups={groups}/>
            </>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <MessageContentPreview message={ thread.isDirect() ? thread.lastMsg : thread.firstMsg } showImage={true} />
          </IonCol>
        </IonRow>
        <IonRow className={'p-0'}>
          <IonCol className={'p-0'}>
            <PostItemFooter thread={thread}/>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
}

export function PostItemFooter ({ thread }: { thread: PostThread }) {
  const dmReplyCount = thread.dmChannelIds.length;

  return (
    <div className='d-flex'>
      <span className={'ml-auto my-auto mr-1 small text-muted'}>
        {dmReplyCount > 0 && <span className='pl-1'><DirectMessagesIcon/>x{dmReplyCount}</span>}
        { ReactionsIcons.map(([r, icon]) => (thread.firstMsg.reactionList[r]
          ? <span key={r} className='pl-1'>{icon}x{thread.firstMsg.reactionList[r]?.length}</span>
          : ''))
        }
      </span>
    </div>
  );
}
