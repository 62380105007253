import { UserLayoutPage } from '../../containers/UserLayout';
import {
  IonButton,
  IonButtons,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonRow,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import { useGetCurrentUser } from '../../models/user';
import React from 'react';
import { UserDropdown } from '../../components/Dropdowns';
import { schemas } from 'sprancer-shared';
import { useResource } from 'rest-hooks';
import { UserPushDeviceResource } from '../../models/userPushDevice';
import { assertNever } from '../../libs/assertions';

export default function UserProfileOverview () {
  return (
    <UserLayoutPage
      header={<Header/>}
      content={<Content />}
    />
  );
}

function Header () {
  return (
    <IonToolbar>
      <IonButtons slot="start">
        <IonMenuButton autoHide={true}/>
      </IonButtons>
      <IonTitle>Notifications</IonTitle>
      <UserDropdown slot="end"/>
    </IonToolbar>
  );
}

function mapFreqToDescription (freq: schemas.FrequencyType): string {
  switch (freq) {
    case 'instant':
    case '5 minutes':
      return 'Instant';
    case 'hour':
      return 'Frequent (at most once an hour)';
    case 'day':
      return 'Normal (at most once a day)';
    case 'week':
      return 'Infrequent (at most once a week)';
    case 'never':
      return 'No Alerts and no app badge)';
    case 'badge':
      return 'No Alerts (app badge only)';
    case 'hide':
      return 'Hidden';
    default:
      assertNever(freq);
  }
  return 'Unknown';
}

function Content () {
  const user = useGetCurrentUser();
  const pushDevices = useResource(UserPushDeviceResource.listShape(), { userId: user.userId });

  return (<>
    <IonRow>
      <IonButton
        className='ml-auto'
        color="primary"
        fill="clear"
        routerLink={'/user/notifications/edit'}
      >Edit</IonButton>
    </IonRow>
    <IonList>
      <IonItem lines='none'>
        <IonLabel className="text-wrap">
          <h2>Frequency</h2>
          <p>Direct Messages: {mapFreqToDescription(user.directMessageNotificationFrequency || schemas.DEFAULT_DIRECT_MESSAGE_FREQUENCY)}</p>
          <p>New Customers: {mapFreqToDescription(user.bizCustomerAlertFrequency || schemas.DEFAULT_BIZ_CUSTOMER_ALERT_FREQUENCY)}</p>
          <p>Everything Else: {mapFreqToDescription(user.notificationFrequency || schemas.DEFAULT_FREQUENCY)}</p>

        </IonLabel>
      </IonItem>
      <IonItem lines='none'>
        <IonLabel className="text-wrap">
          <h2>Email Notifications</h2>
          <p>
            { user.emailAlways
              ? 'Always send'
              : (user.emailEnabled || user.emailEnabled === undefined)
                  ? 'Send if push notifications are not available'
                  : 'Disabled'}
          </p>
        </IonLabel>
      </IonItem>
      <IonItem lines='none'>
        <IonLabel className="text-wrap">
          <h2>Push Notifications</h2>
          <p>
            {user.pushEnabled || user.pushEnabled === undefined
              ? 'Always send'
              : 'Disabled'}
          </p>
          {pushDevices.length > 0
            ? <>
              <p>Devices:</p>
              {pushDevices.map(d =>
                <p className='ml-3' key={d.token}>{d.nameWithDate()}</p>
              )}
            </>
            : <p>No push devices registered.</p>
          }
        </IonLabel>
      </IonItem>
    </IonList>
  </>);
}
