import React from 'react';
import { UserLayoutPage } from '../../containers/UserLayout';
import { IonButtons, IonTitle, IonToolbar, IonButton, IonBackButton } from '@ionic/react';
import { UserDropdown } from '../../components/Dropdowns';
import { useGetCurrentUser } from '../../models/user';

export default function UserNewBusinessHowTo () {
  return (
    <UserLayoutPage
      header={<Header/>}
      content={<Content/>}
    />
  );
}

function Header () {
  return (
    <IonToolbar>
      <IonButtons slot="start">
        <IonBackButton defaultHref={'/user/home'}/>
      </IonButtons>
      <IonTitle>New Business</IonTitle>
      <UserDropdown slot="end"/>
    </IonToolbar>
  );
}

function Content () {
  const user = useGetCurrentUser();

  return (<div className={'px-3'}>
    <h3 className={'text-muted text-center px-1'}>Sprancer-ify Your Business</h3>
    <p>Sprancer makes it easy for your customers connect to your business without worrying about sharing their email or phone number.</p>
    <p>To get your business on Sprancer, get in touch!
      { user.connectToSprancerPath && <> <strong>Connect with us on Sprancer</strong>, or</> }
      <strong> Email us at support@sprancer.com.</strong>
    </p>
    { user.connectToSprancerPath && <div className={'mt-2 text-center'}>
      <IonButton routerLink={user.connectToSprancerPath}>Connect With Sprancer</IonButton>
    </div> }
  </div>);
}
