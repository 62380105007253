import React from 'react';
import { Redirect, Route } from 'react-router';
import SettingsOverview from './SettingsOverview';
import SettingsEdit from './SettingsEdit';
import { Switch } from 'react-router-dom';
import { IonRouterOutletWrapper } from '../../components/RouterOutlet';

export default function Settings () {
  // IonRouterOutletWrapper for an explanation of this nonsense.
  const routes = [
    { path: '/businesses/:businessId/settings/edit', name: 'Settings', component: <SettingsEdit /> },
    { path: '/businesses/:businessId/settings', name: 'Settings', component: <SettingsOverview /> }
  ];

  return (
    <Switch>
      <Route exact path={routes[0].path} name={routes[0].name}><IonRouterOutletWrapper outletRoutes={routes}/></Route>
      <Route exact path={routes[1].path} name={routes[1].name}><IonRouterOutletWrapper outletRoutes={routes}/></Route>
      <Redirect to={'/businesses/:businessId/settings'} />
    </Switch>
  );
}
