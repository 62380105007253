import { AmplifyResource } from './api';
import { schemas } from 'sprancer-shared';
import { Resource } from 'rest-hooks';

export interface ActivationCodeResource extends schemas.ActivationCodeType {}
export class ActivationCodeResource extends AmplifyResource {
  pk () {
    return this.id;
  }

  static redeemCodeShape<T extends typeof Resource> (this: T) {
    return {
      ...this.createShape(),
      fetch: (params: Readonly<Record<string, string>>, body?: Readonly<schemas.ActivationRedeemCodeType>) => {
        return this.fetch('post', '/activationcodes', body);
      }
    };
  }

  static urlRoot = '/activationcodes';
}
