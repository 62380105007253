import React from 'react';
import { UserLayoutPage } from '../../containers/UserLayout';
import {
  IonBackButton,
  IonButtons,
  IonItem,
  IonTitle,
  IonToolbar, IonLabel
} from '@ionic/react';
import { UserDropdown } from '../../components/Dropdowns';
import { useParams } from 'react-router';
import {
  activationSecretIdWithReferrer, generateDownloadFileName, generateLinkWithLocation, QRContent
} from '../../components/Activations';
import { Form, Formik } from 'formik';
import { IonFormikInput } from '../../components/Forms';
import { ReferrerCodeIcon } from '../../libs/icons';
import * as yup from 'yup';
yup.setLocale({
  mixed: {
    required: 'required'
  }
});

// This page is used to generate an arbitrary QR code.  This is for internal Sprancer use so it should move into an
// admin interface eventually.
export default function NewCustomerQR () {
  return (
    <UserLayoutPage
      header={<Header/>}
      content={<Content/>}
    />
  );
}

function Header () {
  const { businessId } = useParams<{ businessId: string }>();

  return (
    <>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton defaultHref={`/businesses/${businessId}/customers/`}/>
        </IonButtons>
        <IonTitle>Add Customer QR</IonTitle>
        <UserDropdown slot="end" />
      </IonToolbar>
    </>
  );
}

const NewCustomerQRFormSchema = yup.object({
  businessName: yup.string().required(),
  activationSecretId: yup.string().required(),
  referrer: yup.string().max(30).matches(/^[a-zA-Y0-9]+$/, 'Must be only numbers or letters (except Z)')
});

type NewCustomerQRFormType = {
  businessName: string,
  activationSecretId: string;
  referrer?: string;
}

function Content () {
  const initialValues: NewCustomerQRFormType = { businessName: '', referrer: '', activationSecretId: '' };

  return (<>
    <Formik
      initialValues={initialValues}
      initialTouched={{ referrer: true }}
      onSubmit={() => { /* No-op */ }}
      validationSchema={NewCustomerQRFormSchema} >
      {({ values }) => {
        const link = generateLinkWithLocation(`/activations/${activationSecretIdWithReferrer(values.activationSecretId, 'C', values.referrer)}`);
        return (
          <Form>
            <IonFormikInput name={'businessName'} type={'text'} placeholder={'Business Name'} label={<ReferrerCodeIcon />}/>
            <IonFormikInput name={'activationSecretId'} type={'text'} placeholder={'Activation Secret Id'} label={<ReferrerCodeIcon />}/>
            <IonFormikInput name={'referrer'} type={'text'} placeholder={'Referrer Code (optional)'} label={<ReferrerCodeIcon />}/>
            <IonItem>
              <IonLabel className={'text-wrap'}>{link}</IonLabel>
            </IonItem>
            <IonItem>
              <div className={'p-3 d-flex flex-column justify-space-between h-100'}>
              <QRContent dlname={generateDownloadFileName(values.businessName, '', values.referrer || '')} link={link} />
              </div>
            </IonItem>
          </Form>
        );
      }}
    </Formik>
  </>);
}
