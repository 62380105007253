import { Route } from 'react-router';
import React from 'react';
import GroupDetails from './GroupDetails';
import GroupDetailsEdit from './GroupDetailsEdit';
import NewGroup from './NewGroup';
import { Redirect, Switch } from 'react-router-dom';
import { IonRouterOutletWrapper } from '../../components/RouterOutlet';

export default function Groups () {
  // IonRouterOutletWrapper for an explanation of this nonsense.
  const routes = [
    { path: '/businesses/:businessId/groups/new/new', name: 'New Group', component: <NewGroup /> },
    { path: '/businesses/:businessId/groups/:groupId', name: 'Group Details', component: <GroupDetails /> },
    { path: '/businesses/:businessId/groups/:groupId/editDetails', name: 'Group Details Edit', component: <GroupDetailsEdit /> }
  ];

  return (
    <Switch>
      <Route exact path={routes[0].path} name={routes[0].name}><IonRouterOutletWrapper outletRoutes={routes}/></Route>
      <Route exact path={routes[1].path} name={routes[1].name}><IonRouterOutletWrapper outletRoutes={routes}/></Route>
      <Route exact path={routes[2].path} name={routes[2].name}><IonRouterOutletWrapper outletRoutes={routes}/></Route>
      <Redirect exact from='/businesses/:businessId/groups' to='/businesses/:businessId/customers'/>
      <Redirect to={'/businesses/:businessId/customers'} />
    </Switch>
  );
}
