import React, { useContext, useState } from 'react';
import { channels } from 'sprancer-shared';
import { useParams } from 'react-router';
import { BusinessContext, UserLayoutPage } from '../../containers/UserLayout';
import {
  IonBackButton,
  IonButtons,
  IonItem,
  IonLabel,
  IonList,
  IonSearchbar,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import { UserDropdown } from '../../components/Dropdowns';
import { AvatarImage } from '../../components/Avatars';
import { MissingSomethingWarning } from '../../components/Warnings';
import { Link } from 'react-router-dom';

import { calculateCustomerIds } from './messageHelpers';

export default function PostNewChat () {
  return (
    <UserLayoutPage
      header={<Header/>}
      content={<Content/>}
    />
  );
}

function Header () {
  const { businessId, threadId } = useParams<{ businessId: string, threadId: string }>();

  return (
    <IonToolbar>
      <IonButtons slot="start">
        <IonBackButton defaultHref={`/businesses/${businessId}/posts/${threadId}`}/>
      </IonButtons>
      <IonTitle>New Direct Message</IonTitle>
      <UserDropdown slot="end"/>
    </IonToolbar>
  );
}

function Content () {
  const { businessId, threadId } = useParams<{ businessId: string, threadId: string }>();
  const { postThreadsById } = useContext(BusinessContext);
  const thread = postThreadsById.get(threadId);
  const { customersById, groupToCustomerIds } = useContext(BusinessContext);
  const [searchRegex, setSearchRegex] = useState<RegExp | null>(null);

  if (!thread || thread.isDirect()) {
    return (<p className='text-muted'>This message is no longer available. Messages expire after 30 days.</p>);
  }

  if (customersById.size === 0) {
    return (
      <MissingSomethingWarning
        title={'No Customers'}
        message={'Please connect to a customer to enable direct messaging.'}
        buttonLink={`/businesses/${businessId}/customers/new/new`}
        buttonTitle={'New Customer'} />
    );
  }

  const allCustomers = calculateCustomerIds(thread.threadGroupIds(), groupToCustomerIds).map(cId => customersById.get(cId));

  // {/*<MissingSomethingWarning warningIcon={false} title={'No Direct Messages'} />*/}
  return (
    <>
      <IonItem lines={'none'}><IonSearchbar onIonChange={e => setSearchRegex(new RegExp(e.detail.value!, 'i'))} /></IonItem>
      <IonItem lines={'none'}><IonLabel className='text-wrap'>Choose a customer:</IonLabel></IonItem>
      <IonList>
        {allCustomers.map(customer => (
          (!customer || (searchRegex && !customer.nickname.match(searchRegex)))
            ? <></>
            : <Link
              key={customer.id}
              replace={true}
              to={{
                pathname: `/businesses/${businessId}/messages/${threadId}/channels/${channels.genDirectChannelId(customer.id)}`,
                state: { newMessage: true }
              }}>
              <IonItem detail={true}>
                <AvatarImage slot='start' className='w-100 avatar-auto' size='md' status={customer.status} avatar={customer.avatar} />
                <IonLabel><h2 className='text-break'>{customer.nickname || <span className={'text-muted'}>No Name</span>}</h2></IonLabel>
              </IonItem>
            </Link>
        ))}
      </IonList>
    </>
  );
}
