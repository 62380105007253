import React, { ErrorInfo } from 'react';
import { reportException } from '../../libs/errors';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow
} from '@ionic/react';
import { ErrorIcon } from '../../libs/icons';

export default class ErrorBoundary extends React.Component<
  React.PropsWithChildren<unknown>,
  { error?: Error }
  > {
  constructor (props: { children?: React.ReactNode; } | Readonly<{ children?: React.ReactNode; }>) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError (error: Error) {
    return { error };
  }

  componentDidCatch (error: Error, errorInfo: ErrorInfo) {
    error.stack += '\nerrorInfo.componentStack\n' + errorInfo.componentStack;
    reportException(error, 'componentDidCatch in ErrorBoundary');
    this.setState({ error });
    return false;
  }

  render () {
    if (this.state.error) {
      return (
        <IonPage id="main">
          <IonContent forceOverscroll={false}>
            <IonGrid>
              <IonRow class="ion-align-items-center" style={{ minHeight: '90vh' }}>
                <IonCol size={'12'} size-md={'8'} offset-md={'2'} size-xl={'6'} offset-xl={'3'}>
                  <div className={'ion-text-center text-secondary'}><ErrorIcon size={'50%'}/></div>
                  <h3 className={'ion-text-center'}>An error occurred while communicating with Sprancer.</h3>
                  <p className={'ion-text-center'}>Please check your network connection and <a href={'/'}>try again</a>.</p>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonPage>
      );
    }
    return React.Children.only(this.props.children);
  }
}
