import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { constants } from 'sprancer-shared';
import { initErrorReporter, reportException } from './libs/errors';
import { Amplify } from 'aws-amplify';
import API from '@aws-amplify/api';
import config from './config';
import { CacheProvider } from 'rest-hooks';
import ErrorBoundary from './views/Errors/ErrorBoundary';
import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;

const MEMORY_KEY_PREFIX = '@IonicAuthStorage:';
let dataMemory: Record<string, string> = {};

class IonicAuthStorage {
  static syncPromise: Promise<void> | null = null;
  /**
   * This is used to set a specific item in storage
   */
  static setItem (key: string, value: string) {
    Storage.set({ key: MEMORY_KEY_PREFIX + key, value: value }).catch((e) => {
      reportException(e, 'Storage.set failed in index IonicAuthStorage setItem');
    });
    dataMemory[key] = value;
    return dataMemory[key];
  }

  /**
   * This is used to get a specific key from storage
   */
  static getItem (key: string) {
    return Object.prototype.hasOwnProperty.call(dataMemory, key) ? dataMemory[key] : undefined;
  }

  /**
   * This is used to remove an item from storage
   */
  static removeItem (key: string) {
    Storage.remove({ key: MEMORY_KEY_PREFIX + key }).catch((e) => {
      reportException(e, 'Storage.remove failed in index IonicAuthStorage removeItem');
    });
    return delete dataMemory[key];
  }

  /**
   * This is used to clear the storage
   */
  static clear () {
    dataMemory = {};
    return dataMemory;
  }

  /**
   * Will sync the MemoryStorage data from AsyncStorage to storageWindow MemoryStorage
   */
  static sync () {
    if (!IonicAuthStorage.syncPromise) {
      IonicAuthStorage.syncPromise = new Promise((resolve, reject) => {
        Storage.keys().then(async keyResults => {
          for (let i = 0; i < keyResults.keys.length; i++) {
            const key = keyResults.keys[i];
            if (key.startsWith(MEMORY_KEY_PREFIX)) {
              try {
                const valResults = await Storage.get({ key });
                if (valResults.value !== null) {
                  const memoryKey = key.replace(MEMORY_KEY_PREFIX, '');
                  dataMemory[memoryKey] = valResults.value;
                }
              } catch (e) {
                reportException(e, 'Storage.get failed in index IonicAuthStorage sync');
                reject(e);
              }
            }
          }
          resolve();
        }).catch((e) => {
          reportException(e, 'Storage.keys failed in index IonicAuthStorage sync');
          reject(e);
        });
      });
    }
    return IonicAuthStorage.syncPromise;
  }
}

Amplify.register(API);
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    storage: IonicAuthStorage
  },
  API: {
    endpoints: [
      {
        name: constants.API_NAME,
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      }
    ]
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  }
});

initErrorReporter(config.sentryio.dsn);
ReactDOM.render(<ErrorBoundary><CacheProvider><App /></CacheProvider></ErrorBoundary>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
