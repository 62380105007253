import * as Sentry from '@sentry/browser';
import { Capacitor, Plugins } from '@capacitor/core';
import { isPlatform } from '@ionic/react';
import { Primitive } from '@sentry/types';
const { Device } = Plugins;

const deviceInfo: { [key: string]: Primitive; } = {};
Device.getInfo().then(di => {
  deviceInfo.appName = di.appName;
  deviceInfo.appVersion = di.appVersion;
  deviceInfo.appBuild = di.appBuild;
  deviceInfo.platform = di.platform;
}).catch(() => {
  // ignore
  console.log('Failed to get device info');
});

export function initErrorReporter (sentryDsn: string) {
  if (sentryDsn) {
    Sentry.init({
      dsn: sentryDsn,
      environment: process.env.REACT_APP_STAGE
    });
  }
}

export function reportException (
  origError: string | Error & { cause?: Error | string, tags?: { [key: string]: Primitive; } },
  description: string
) {
  let e: (Error & { cause?: Error | string, tags?: { [key: string]: Primitive; }});

  // Normalize the error to a new error object
  if (typeof origError === 'string') {
    e = new Error(origError);
    e.stack = '';
  } else {
    e = new Error(origError.message);
    e.cause = origError;
    e.stack = origError.stack;
  }

  // Annotate the error with description and stack
  e.message = [description, e.message].join(': ');
  e.stack = (e.stack ? '' : e.stack + '\n') + 'reportExceptionStack:' + new Error().stack;

  console.log('ReportException:\n', e);

  if (isPlatform('hybrid')) {
    try {
      Capacitor.toNative && Capacitor.toNative('Console', 'log', { message: 'ReportException:\n' + JSON.stringify(e) });
    } catch (logError) {
      // ignore
    }
  }

  Sentry.withScope(scope => {
    if (e.tags) {
      scope.setTags({ ...deviceInfo, ...e.tags });
    } else {
      scope.setTags(deviceInfo);
    }

    if (e.cause) {
      scope.setExtra('cause', JSON.stringify(e.cause));
    }

    Sentry.captureException(e);
  });
}
