import { Storage } from 'aws-amplify';
import { nanoid } from 'nanoid';
import { images } from 'sprancer-shared';

export async function s3UploadBusinessLogo (file: File, metadata?: Record<string, string>): Promise<string> {
  const key = `${Date.now()}-${images.BUSINESSLOGO_IMAGE_TYPE}-${nanoid()}`;

  await Storage.put(key, file, {
    level: 'private',
    contentType: file.type,
    ...metadata && { metadata }
  });

  return key;
}

export async function s3UploadMessageImage (file: File, metadata?: Record<string, string>): Promise<string> {
  const key = `${Date.now()}-${images.MESSAGEIMAGE_IMAGE_TYPE}-${nanoid()}`;

  await Storage.put(key, file, {
    level: 'private',
    contentType: file.type,
    ...metadata && { metadata }
  });

  return key;
}

export async function getS3Url (s3Name: string): Promise<string> {
  return Storage.get(s3Name, { level: 'private', download: false }) as Promise<string>;
}
